export const stateList =
[
  {
    "id": 1,
    "name": "New South Wales",
    "code": "NSW"
  },
  {
    "id": 2,
    "name": "Queensland",
    "code": "QLD"
  },
  {
    "id": 3,
    "name": "South Australia",
    "code": "SA"
  },
  {
    "id": 4,
    "name": "Tasmania",
    "code": "TAS"
  },
  {
    "id": 5,
    "name": "Victoria",
    "code": "VIC"
  },
  {
    "id": 6,
    "name": "Western Australia",
    "code": "WA"
  },
  {
    "id": 7,
    "name": "Australian Capital Territory",
    "code": "ACT"
  },
  {
    "id": 8,
    "name": "Northern Territory",
    "code": "NT"
  }
]
