import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss',
})
export class PrivacyPolicyComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('AANT Travel insurance | Privacy Policy');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Read the Travel Insurance Privacy Policy to understand how we protect your personal information. Learn about our data collection practices, security measures, and your privacy rights. We prioritize your data security.',
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: `AANT travel insurance privacy
                  Travel insurance privacy policy
                  AANT policy on privacy
                  Insurance data privacy
                  Travel insurance data protection
                  AANT privacy terms`,
    });
  }
}
