import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-expired-quote',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './expired-quote.component.html',
  styleUrl: './expired-quote.component.scss',
})
export class ExpiredQuoteComponent implements OnInit {
  isMobile: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isMobile = window.innerWidth < 820;
  }

  async ngOnInit() {
    this.isMobile = window.innerWidth < 820;
  }
}
