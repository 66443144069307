<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'WhatsCovered'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/what-cover.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Ski, Snowboard & Winter Sports Cover</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <p>
              Imagine you’re speeding down the slopes of a mountain and the
              fantastically cool breeze makes you feel like you are entering
              another world. You take a deep breath and inhale the crisp, calm
              air focusing on your ability to glide through the snow like a
              professional.
            </p>
            <p>
              Whether you are practicing on the bunny slopes, shredding the most
              exhilarating courses, ice skating or snowboarding, you shouldn’t
              be worried about your level of
              <a href="/travel-insurance">travel insurance coverage</a>. From
              New Zealand to Japan, from the Americas to our own Aussie slopes,
              you are free to enjoy the wonders of the frosty places you’ve
              always wanted to visit with World2Cover’s ski and winter sports
              cover<sup>#&amp;</sup>. For an additional premium our ski and
              winter sports option could provide the cover you may need.
            </p>

            <p class="font-12">
              #Limits and sub-limits apply <br />
              &Policy criteria and conditions apply
            </p>
          </div>

          <div class="page-content-info">
            <div class="card">
              <div class="card-body">
                <h3>
                  What is ski and winter sports travel insurance<sup>Ω</sup>?
                </h3>
                <p>
                  World2Cover’s ski and winter sports cover allows you to have
                  added peace of mind when engaging in winter sports activities.
                  For an additional premium we offer cover for many winter
                  sports. Of course, not all winter sports are covered, for full
                  details see our
                  <a href="/pds">Product Disclosure Statement</a> under
                  activities not covered.
                </p>

                <b>Under this option we can cover you for:</b>
                <ul class="check-circle">
                  <li>
                    Overseas medical and hospital cover for accidents you may
                    have on the slopes<sup>#β</sup>
                  </li>
                  <li>
                    The costs of hiring alternative snow skiing equipment in
                    certain circumstances<sup>&amp;#</sup>
                  </li>
                  <li>
                    Ski Pack cover ^ - claim any unused pre-booked and pre-paid
                    ski passes, ski hire, tuition fees or lift passes that you
                    cannot use due to injury or illness<sup>&amp;#</sup>
                  </li>
                  <li>
                    Piste closure^<sup>#&nbsp;</sup>-&nbsp;when overseas you're
                    covered for travel to the nearest alternate resort or for
                    the purchase of additional ski passes if you're unable to
                    ski at your pre-booked resort due to piste closure
                    <sup>β</sup>
                  </li>
                  <li>
                    Bad weather and avalanche^ - if you're delayed by more than
                    12 hours on your outward journey, we’ll cover your
                    reasonable travel and accommodation expenses
                  </li>
                  <li>
                    Lift pass theft - if your pass is stolen or lost, we'll
                    cover the cost of purchasing a new one<sup>&amp;</sup>
                  </li>
                </ul>

                <div class="bg-light p-3 rounded font-12">
                  ^This cover is per policy<br />
                  #Limits and sub-limits apply<br />
                  &amp;Policy criteria and conditions apply<br />
                  ΩExcluding Basics Cover<br />
                  βExcluding Domestic Cover
                </div>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <div class="card">
              <div class="card-body">
                <h3>
                  What activities are covered under the ski and winter sports
                  option?
                </h3>
                <p>
                  With World2Cover ski and winter sports travel insurance
                  option, the following activities are covered under your
                  policy:
                </p>
                <ul class="check-circle">
                  <li>Skiing</li>
                  <li>Snowboarding</li>
                  <li>Leisure Bigfoot skiing</li>
                  <li>Cat skiing</li>
                  <li>
                    Cross country skiing (along a designated cross-country Ski
                    route only)
                  </li>
                  <li>Glacier Skiing</li>
                  <li>Ice hockey</li>
                  <li>Ice skating</li>
                  <li>Luging (on ice only)</li>
                  <li>Mono skiing</li>
                  <li>Off–piste Skiing (with a professional guide only)</li>
                  <li>Recreational Skiing</li>
                  <li>Snowmobiling and tobogganing</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Why consider World2Cover?</h3>
            <p>
              World2Cover offers specially designed levels of cover to assist
              with your travel insurance needs and can help to provide you with
              peace of mind. We strive to provide superior customer service,
              travel insurance packages, and instant quotes. You have heaps of
              places to go, people to see, and adventures to experience. Take
              advantage of our packages because, as we know, you have the world
              to cover. Get an instant online quote today!
            </p>
          </div>

          <div class="page-content-info">
            <p class="font-12">
              Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438 291
              AFSL 246 548) is the issuer of World2Cover Travel Insurance. The
              'World2Cover Travel Insurance - Combined Financial Services Guide
              and Product Disclosure Statement' (PDS) which includes the policy
              wording, is available <a href="/pds">here</a>. Any financial
              advice set out above is general in nature only, and does not take
              into account your objectives, financial situation or needs.
              Because of this, you should, before acting on the advice, consider
              the appropriateness of the advice, having regards to your
              objectives, financial situation and needs. You should consider the
              PDS in deciding whether to acquire, or to continue to hold the
              policies.
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
