<div class="theme-steps">
  <racv-app-header></racv-app-header>
  <section class="step4 inner-page-content error-page">
    <div class="container">
      <div class="privacy box-panel last-panel">
        <div class="row pt-2">
          <div class="col-md-12">
            <h4>The page you were looking for does not exist</h4>
            <p>We could not find the page you requested. You can either:</p>
            <ul class="pay-disclaimer">
              <li>
                <a href="javascript:void(0);" (click)="goToStepOne()"
                  >Return to the homepage</a
                >
              </li>
              <li>
                Phone customer support on
                <strong>(08) 8925 5901</strong>
              </li>
            </ul>
            <h4>Emergency assistance</h4>
            <p>
              In the event you need assistance when travelling you can call our
              Worldwide emergency assistance number:
            </p>
            <p>
              <strong
                ><a href="tel:+61 2 8055 1625"> +61 2 8055 1625</a></strong
              >
            </p>
          </div>
          <div class="col-md-12">
            <p>{{ errorMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-racv-footer></app-racv-footer>
</div>
