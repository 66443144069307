<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/privacy-policy.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1> Privacy Policy</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          

          <div class="page-content-info">
            <!-- <h3>Privacy policy</h3> -->
            
            <ul>
              <li><a href="https://www.tokiomarine.com.au/wp-content/uploads/2023/09/Privacy-Policy-2023.pdf" target="_blank">Tokio Marine & Nichido Fire Insurance Co., Ltd. Privacy Policy</a></li>
              <li><a href="https://www.aant.com.au/sites/default/files/uploads/files/4643-aant-privacypolicybrochure.pdf" target="_blank">Automobile Association of the Northern Territory Privacy Policy</a></li>
            </ul>
           
          </div>
 
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
