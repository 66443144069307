<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'WhatsCovered'"
    ></app-racv-common-header>
  </section>
  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/what-cover.png"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Top Cover</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <p class="mb-0">
              Considering purchasing travel insurance for your upcoming trip?
              Good on ya!
            </p>
            <p>
              Our maximum level of cover allows you to focus on the important
              things in life, like planning new adventures with family and
              friends.
            </p>
          </div>
          <div class="page-content-info">
            <h3>What is covered with our top cover travel insurance?</h3>
            <p class="mb-0">
              When it comes to our top cover travel insurance, World2Cover
              provides you with added peace of mind on a wide range of items.
              From cancellation fees and lost deposits to medical expenses, we
              have a level of cover that might be right for you<sup>#</sup>.
            </p>
            <p class="mb-0">
              Our new for old policy feature is one of the many offered benefits
              on our top cover policy. New for old means that we keep the same
              level of value of your damaged items intact. For example, if
              you’re entitled to a replacement of damaged luggage, we will
              replace it with brand new luggage<sup>#</sup> up to the maximum
              amounts and specific limits of coverage. Please note that a pair
              or set of items is treated as one item (e.g. a pair of earrings, a
              camera body and its standard lens and accessories, or a set of
              golf clubs or a drone with its camera).
            </p>
            <p>
              Your International Single Trip Top cover includes Limited COVID-19
              benefits<sup>&</sup> please read our Product Disclosure Statement
            </p>

            <p class="font-12 my-2">#Limits and sub-limits apply</p>
            <p class="font-12 my-2">&Policy criteria and conditions apply</p>
          </div>

          <div class="page-content-info">
            <h3>
              Summary of covered items<sup><small>#</small></sup>
            </h3>
            <div class="card">
              <div class="card-body">
                <ul class="check-circle">
                  <li>$Unlimited** Overseas medical and hospital expenses</li>
                  <li>
                    $Unlimited** COVID-19 Overseas medical and hospital and
                    emergency expenses<sup>#</sup>
                  </li>
                  <li>
                    $Unlimited** emergency medical &amp; evacuation expenses
                  </li>
                  <li>
                    COVID-19 Cancellation fees and lost deposits, additional
                    expenses<sup>#</sup> and special events, travel delay<sup
                      >#</sup
                    >
                    (Up to $3,500 per adult combined limit)
                  </li>
                  <li>
                    $Unlimited^^ cancellation fees &amp; lost deposits<sup
                      >#</sup
                    >
                  </li>
                  <li>$Unlimited^^ emergency expenses<sup>#</sup></li>
                  <li>Cash in Hospital<sup>#</sup> (up to $6,000)</li>
                  <li>Overseas Dental<sup>#</sup> (up to $2,000)</li>
                  <li>Domestic pets^<sup>#</sup></li>
                  <li>Domestic services^<sup>#</sup></li>
                  <li>24/7 global Medical emergency assistance</li>
                  <li>Travel agent fees</li>
                  <li>
                    Luggage<sup>#</sup> loss, theft, damage or delayed&nbsp;
                  </li>
                  <li>Travel documents &amp; personal money</li>
                  <li>Rental vehicle excess<sup>#</sup></li>
                  <li>Accidental Death* &amp; Funeral expenses Overseas*</li>
                  <li>Legal liability^&amp; (up to $10,000,000)</li>
                  <li>
                    38 listed existing medical conditions covered<sup
                      >&amp;</sup
                    >
                  </li>
                  <li>
                    Most amateur sporting and adventure activities are covered
                    at no extra cost such as hot air ballooning and bungee
                    jumping<sup>&amp;</sup>
                  </li>
                </ul>

                <div class="bg-light p-3 rounded font-12">
                  #Limits and sub-limits apply <br />
                  **$unlimited means that generally there is no cap on the
                  maximum dollar amount which may be paid out of this benefit,
                  subject to the specific terms and conditions, sub-limits and
                  exclusion apply to this benefit. This benefit covers
                  reasonable overseas medical and hospital costs as a result of
                  an injury (including arising from a terrorist act) or illness
                  occurring which first shows itself during your period of
                  insurance. Benefits may be paid up to 12 months from the time
                  you received treatment for the injury or illness, but only for
                  reasonable expenses incurred during that time. All medical
                  treatments must be provided by your treating doctor or our
                  consulting medical officer. You must notify us as soon as
                  possible of your admittance to hospital. ^^$unlimited means
                  that generally there is no cap on the maximum dollar amount
                  which may be paid out for this benefit, subject to the
                  specific terms and conditions, sub-limits and exclusions that
                  apply to this benefit. <br />
                  ^This cover is per policy <br />
                  *This cover is per person listed in Your Policy <br />
                  & Policy criteria and conditions apply
                </div>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Who does top cover travel insurance cover?</h3>
            <p>
              Top Cover travel insurance offers coverage for both singles and
              families. Singles include one adult between the ages of 18 and 75
              and their declared dependents. Family cover includes any two
              adults named on the policy travelling together and their declared
              dependents. Dependents who are not working full-time, are aged 25
              and under and travelling with you for the entire trip can be
              listed on the single and family travel insurance package. This is
              included at no additional cost, unless they have an existing
              medical condition to declare (in which case an additional premium
              may be required).
            </p>
            <p>
              World2Cover top cover also provides coverage for domestic
              pets<sup>#</sup>^. We consider pets to be a member of the family,
              so they deserve to be protected like family. With certain
              restrictions<sup>∆</sup>, Top cover travel insurance covers up to
              $650 if your pet suffers an injury during your journey and
              requires veterinary treatment (provided that at the time of injury
              your pet was in the care of a relative, friend or boarding kennel
              or cattery).
            </p>
            <p>
              $25 for each 24-hour period in respect of additional boarding
              kennel or cattery fees for domestic dogs and cats owned by You, if
              You are delayed beyond Your original return date due to an event
              covered under this policy<sup>&amp;</sup>.
            </p>
            <p>
              Cover is available to Australian residents under two conditions:
              your trip begins and ends in Australia and you purchase your
              policy ahead of your trip. Temporary residents<sup>&amp;</sup> may
              also obtain cover under certain stipulations, please refer to our
              <a href="/pds">Product Disclosure Statement</a>.
            </p>
            <p class="font-12">
              <span
                >#Limits and sub-limits apply<br />
                ^This cover is per policy<br />
                &amp; Policy criteria and conditions apply<br />
                ∆We will not cover you for Any boarding kennel or cattery fees
                incurred outside of Australia, any pets located outside of
                Australia and all General Exclusions apply, please refer to our
                Product Disclosure Statement"</span
              >
            </p>
          </div>

          <div class="page-content-info">
            <h3>How much does top cover travel insurance cost?</h3>
            <p>
              <a href="/travel-insurance">Travel insurance</a> costs depend on a
              variety of factors. We take into consideration where you are
              travelling, for how long, the age of each adult traveller, and
              more. If you lodge a claim on our top travel insurance package,
              the standard excess is $200 AUD or you can reduce your excess to
              $100 or $0 by paying extra. Excess is charged on per incident
              basis. Get personalised pricing with our instant quote button.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Cooling-off period and extending your cover</h3>
            <p>
              You are able to cancel your policy at no cost to you 21 days from
              the date of issue, provided the trip has not started and you don’t
              want to make a claim or exercise any other right under the
              policy.&nbsp;
            </p>
            <p>
              Your trip can be extended free of charge if your departure or
              return is delayed due to a reason covered under the policy. If you
              are travelling with your pet and delayed beyond your original
              return date due to an event covered under this policy, you can be
              reimbursed $25 for each 24-hour period of additional boarding
              kennel or cattery fees for domestic dogs and cats∆.
            </p>
            <p>
              For more information on your policy, please visit the
              <a href="/pds">Product Disclosure Statement</a>&nbsp;on our
              website.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Emergency expenses</h3>
            <p>
              World2Cover understands that circumstances happen out of your
              control. Our top cover travel insurance can provide cover for
              accommodation, meals and travel expenses<sup>&amp;</sup> if your
              trip is disrupted due to serious injury, death in the family,
              natural disaster, civil unrest or riots or other reason you can
              claim for under this section. Please visit the
              <a href="/pds">Product Disclosure Statement</a>&nbsp;section 4 on
              our website.
            </p>
            <p class="font-12">
              <span>&amp;Terms, conditions and exclusions apply</span>
            </p>
          </div>

          <div class="page-content-info">
            <h3>Why consider World2Cover?</h3>
            <p>
              World2Cover offers specially designed levels of cover to assist
              with your travel insurance needs and can help to provide you with
              peace of mind. We strive to provide superior customer service,
              travel insurance packages, and instant quotes. You have heaps of
              places to go, people to see, and adventures to experience. Take
              advantage of our packages because, as we know, you have the world
              to cover. Give us a call at
              <a href="tel:0292257599">02 9225 7599</a> or get an instant online
              quote today!
            </p>
            <p class="font-12">
              <span
                >Tokio Marine &amp; Nichido Fire Insurance Co Ltd (ABN 80 000
                438 291 AFSL 246 548) is the issuer of World2Cover Travel
                Insurance. The 'World2Cover Travel Insurance - Combined
                Financial Services Guide and Product Disclosure Statement' (PDS)
                which includes the policy wording, is available </span
              ><a href="/pds">here</a
              ><span
                >. Any financial advice set out above is general in nature only,
                and does not take into account your objectives, financial
                situation or needs. Because of this, you should, before acting
                on the advice, consider the appropriateness of the advice,
                having regards to your objectives, financial situation and
                needs. You should consider the PDS in deciding whether to
                acquire, or to continue to hold the policies.&nbsp;</span
              >
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
