// form-validation.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TravelerValidatorService {
  private validateFormTriggerSubject = new Subject<void>();
  validateTriggerForm$ = this.validateFormTriggerSubject.asObservable();

  private validateFormSubject = new Subject<TravelerFormValidator>();
  validateFormSubject$ = this.validateFormSubject.asObservable();

  private triggerSubmittionSubject = new Subject<SubmissionStatus>();
  triggerSubmittionForm$ = this.triggerSubmittionSubject.asObservable();

  private submittionStatus = new Subject<SubmissionStatus>();
  triggeredSubmittionStatus$ = this.submittionStatus.asObservable();

  triggerFormValidation() {
    this.validateFormTriggerSubject.next();
  }
  validateForm(validate:any) {
    this.validateFormSubject.next(validate);
  }

  triggerSubmission(submission: SubmissionStatus) {
    this.triggerSubmittionSubject.next(submission);
  }
  submitFormStatus(submission: SubmissionStatus){
    this.submittionStatus.next(submission);
  }
}

export interface TravelerFormValidator {
    IsValid: boolean;
}

export interface SubmissionStatus {
  Status: boolean;
  Email: string;
}
