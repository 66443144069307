
<h1 mat-dialog-title class="title">Medical Assessment</h1>
<a data-bs-dismiss="offcanvas" class="close-button" aria-label="Close"><img class="close-button-image" src="assets/icons/aant/cancel.png"
        (click)="closeDialog()" /></a>
<div mat-dialog-content>
    <div id="verisk-widget-container">
        <form class="widget-form" role="form">
            <input id="hdResult" name="hdResult" value="" hidden="true">
            <div class="verisk-container" id="BlackBoxContainer"></div>
        </form>
    </div>

</div>


