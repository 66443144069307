import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'amountFormat',
  standalone: true,
})
export class AmountPipe implements PipeTransform {

  transform(value: any): string {
    if (value === null || value === undefined || value === '') {
      return '0.00';
    }

    const num = Number(value);
    return isNaN(num) || num === 0 ? '0.00' : `${num}`;
  }
}
