<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>COVID-19 – what you need to know</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container page-content-info">
      <p>
        <span
          >As the world and Australia watch the evolution and spread of COVID-19
          coronavirus, there are many questions that arise, particularly in
          connection with travel to international destinations. We want our
          Members to be informed in these trying times and have all necessary
          information available. The following Frequently Asked Questions (FAQs)
          have been developed to assist in providing clarity on situations you
          may find yourself in, and how those situations relates to travel
          insurance.
        </span>
      </p>
      <p>
        <span
          >All coverage is assessed on the standard terms and conditions of your
          policy, which is located in the relevant Combined Financial Service
          Guide and Product Disclosure Statement (PDS).&nbsp;The definition
          provided under the section titled ‘What is a Medical Condition’ may be
          of particular relevance in these circumstances. &nbsp;&nbsp;</span
        >
      </p>
      <p>
        <span
          >The World Health Organisation (WHO) has also provided comprehensive
          FAQs which can be found here - </span
        ><span
          ><a
            href="https://www.who.int/news-room/q-a-detail/q-a-coronaviruses"
            target="_blank"
            ><span
              >https://www.who.int/news-room/q-a-detail/q-a-coronaviruses</span
            ></a
          ></span
        >
      </p>
      <p><span>&nbsp;</span></p>
      <p>
        <strong
          ><span style="text-decoration: underline"
            >I am currently travelling overseas</span
          ></strong
        >
      </p>
      <ol>
        <li>
          <strong
            ><span
              >What coverage do I have for COVID-19 coronavirus under my travel
              insurance policy?</span
            ></strong
          >
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose policies were issued before 20 January 2020
                at 7am (AEST) for travel to any country; or</span
              >
            </li>
            <li>
              <span
                >For customers whose policies were issued after 20 January 2020
                at 7am (AEST) and before 31 January 2020 at 7am (AEST) for
                travel to any country (excluding China); or</span
              >
            </li>
            <li>
              <span
                >For customers whose polices were issued after 31 January 2020
                after 7am (AEST) for travel to all other countries.
              </span>
            </li>
          </ol>
          <p>
            <strong><span>Overseas Medical</span></strong>
          </p>
          <p>
            <span>If you contract COVID-19 coronavirus while overseas</span
            ><span
              >, and as a result are hospitalised or are held in quarantine
              overseas, you may be covered for overseas medical expenses and
              associated expenses under Section 2 of the policy, provided you
              had not entered into in a ‘Do Not Travel’ region at the time. If
              you are then deemed unfit to travel there may be limited cover
              relating to rescheduling your trip.
            </span>
          </p>
          <p>
            <span
              >Please contact the emergency assistance team, available 24 hours
              a day, 7 days a week. Their contact details can be found in the
              PDS under Overseas Emergency Assistance.</span
            >
          </p>
          <p>
            <span
              >All coverage is assessed on the standard terms and conditions of
              your policy. The definition provided under the section titled
              ‘What is a Medical Condition’ may be of particular relevance in
              these circumstances.&nbsp;&nbsp;
            </span>
          </p>
          <p>
            <strong><span>Other sections of the policy</span></strong>
          </p>
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose policies were issued before 20 January 2020
                at 7am (AEST) for travel to any country; or
              </span>
            </li>
            <li>
              <span
                >For customers whose policies were issued after 20 January 2020
                at 7am (AEST) and before 31 January 2020 at 7am (AEST) for
                travel to any country (excluding China).</span
              >
            </li>
          </ol>
          <p>
            <span
              >If the Department of Foreign Affairs and Trade (DFAT) issues a
              ´Do Not Travel’ while you are overseas that affects your travel
              plans there may be cover under Section 1 for rescheduling your
              trip. There may also be cover under Section 1 if another country
              restricts your entry whilst you are on your Trip.</span
            >
          </p>
          <p>
            <span
              >Under section 4 Emergency Expenses, there may be cover for the
              sudden death illness of your close Relative in Australia or
              overseas Travelling Companion, due to COVID-19 coronavirus.</span
            >
          </p>
          <p>
            <span
              >All coverage is assessed on the standard terms and conditions of
              your policy. The definition provided under the section titled
              ‘What is a Medical Condition’ may be of particular relevance in
              these circumstances.&nbsp;&nbsp;
            </span>
          </p>
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose polices were issued after 31 January 2020
                after 7am (AEST) for travel to all other countries.</span
              >
            </li>
          </ol>
          <p>
            <span
              >The policy you purchased does not include cover for the COVID-19
              coronavirus under the terms and conditions of the policy as
              described in the PDS made available to you prior to policy
              purchase. COVID-19 coronavirus was a known circumstance when you
              bought your policy.
            </span>
          </p>
          <p>
            <span
              >Any claims relating to government restrictions including Do Not
              Travel are also not covered.</span
            >
          </p>
          <p><span>&nbsp;</span></p>
        </li>
        <li>
          <strong
            ><span
              >What coverage do I have under my travel insurance policy for
              events that are not related to COVID-19 coronavirus</span
            ></strong
          >
          <p>
            <span
              >Any policy purchased, regardless of when the purchase date was,
              may have coverage for other events under all sections of the
              policy. Please refer to the PDS for the standard terms,
              conditions, limits and exclusions. Some examples include: your
              passport is stolen in Canada, an earthquake in Greece, or your
              luggage delayed in Indonesia for 20 hours.
            </span>
          </p>
          <p>
            <strong><span>&nbsp;</span></strong>
          </p>
          <p>
            <strong
              ><span style="text-decoration: underline"
                >I have not started my trip</span
              ></strong
            >
          </p>
          <p>
            <span
              >Cover for Section 1 Cancellation and Lost Deposits and Section 10
              Financial Default starts on the day your policy is issued. Cover
              for all other benefits commences on the day your trip begins.
            </span>
          </p>
        </li>
        <li>
          <strong
            ><span
              >What coverage do I have for COVID-19 coronavirus under my travel
              insurance policy?</span
            ></strong
          >
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose policies were issued before 20 January 2020
                at 7am (AEST) for travel to any country; or</span
              >
            </li>
            <li>
              <span
                >For customers whose policies were issued after 20 January 2020
                at 7am (AEST) and before 31 January 2020 at 7am (AEST) for
                travel to any country (excluding China).
              </span>
            </li>
          </ol>
          <p>
            <strong><span>Medical in Australia</span></strong>
          </p>
          <p>
            <span
              >There is no medical coverage within Australia under any section
              of the policy. This means if you contract COVID-19 coronavirus:
              medical, ambulance or hospital expenses will not be covered.
            </span>
          </p>
          <p>
            <strong><span>Cancellation</span></strong>
          </p>
          <p>
            <span
              >The policy you purchased includes cover for the COVID-19
              coronavirus under the terms, conditions, limits and exclusion of
              the policy as described in the PDS made available to you prior to
              policy purchase. You may need to either cancel or reschedule/defer
              your trip. The most common examples &nbsp;include:</span
            >
          </p>
          <p>
            <span
              >You contracted COVID-19 coronavirus before your trip began;
              or</span
            >
          </p>
          <p>
            <span
              >The country/location you are travelling to now has imposed
              quarantine periods once you arrive; or</span
            >
          </p>
          <p>
            <span
              >The country you are travelling to has closed its borders;
              or</span
            >
          </p>
          <p>
            <span
              >The Australian government has upgraded the travel warning on
              smartraveller to 'Do Not Travel' for the country you are
              travelling to; or</span
            >
          </p>
          <p>
            <span>The cruise provider has cancelled upcoming sailings; or</span>
          </p>
          <p><span>The airline provider has re-routed your flight.</span></p>
          <p>
            <span>In the first instance please contact your travel </span
            ><span
              >provider/s to discuss any actual or proposed changes to travel
              plans for trips booked up until 30 March 2021. Your travel
              provider can provide you with more information about your options
              for adjusting your travel itinerary or obtaining refunds or
              credits.
            </span>
          </p>
          <p>
            <span
              >There is no cover for a change of plans resulting from you
              changing your mind or due to the fear of contracting COVID-19
              coronavirus.</span
            >
          </p>
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose polices were issued after 31 January 2020
                after 7am (AEST) for travel to all other countries
              </span>
            </li>
          </ol>
          <p>
            <strong><span>Medical in Australia</span></strong>
          </p>
          <p>
            <span
              >There is no medical coverage within Australia under any section
              of the policy. This means if you contract COVID-19 coronavirus:
              medical, ambulance or hospital expenses will not be covered.
            </span>
          </p>
          <p>
            <strong><span>Other sections of the policy</span></strong>
          </p>
          <p>
            <span
              >The policy you purchased does not include cover for the COVID-19
              coronavirus under the terms and conditions of the policy as
              described in the PDS made available to you prior to policy
              purchase. COVID-19 coronavirus was a known circumstance when you
              bought your policy.
            </span>
          </p>
          <p>
            <span
              >Any claims relating to government restrictions including Do Not
              Travel or quarantine periods are also not covered.</span
            >
          </p>
        </li>
        <li>
          <span
            ><a
              href="https://www.worldnomads.com.au/help/insurance/recent-events/coronavirus-faqs#government"
              target="_blank"
              ><strong
                ><span style="text-decoration: none"
                  >Is cover available if the Australian government says not to
                  travel to a destination?</span
                ></strong
              ></a
            ></span
          >
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose policies were issued before 20 January 2020
                at 7am (AEST) for travel to any country; or</span
              >
            </li>
            <li>
              <span
                >For customers whose policies were issued after 20 January 2020
                at 7am (AEST) and before 31 January 2020 at 7am (AEST) for
                travel to any country (excluding China)</span
              >
            </li>
          </ol>
          <p>
            <span
              >If the Department of Foreign Affairs and Trade (DFAT) issues a
              ´Do Not Travel’ while you are overseas or before you depart that
              affects your travel plans, there may be cover under Section 1
              <span style="text-decoration: underline"> </span>for rescheduling
              your trip. There may also be cover under Section 1 if another
              country restricts your entry whilst you are on your Trip.</span
            >
          </p>
          <ol style="list-style-type: lower-roman">
            <li>
              <span
                >For customers whose polices were issued after 31 January 2020
                after 7am (AEST) for travel to all other countries
              </span>
            </li>
          </ol>
          <p>
            <span
              >The policy you purchased does not include cover for the COVID-19
              coronavirus under the terms and conditions of the policy as
              described in the PDS made available to you prior to policy
              purchase. COVID-19 coronavirus was a known circumstance when you
              bought your policy. Any claims relating to ‘Do Not Travel’
              restrictions are not covered.</span
            >
          </p>
          <p><span>&nbsp;</span></p>
          <p>
            <strong><span>General Questions</span></strong>
          </p>
        </li>
        <li>
          <span
            ><a
              href="https://www.worldnomads.com.au/help/insurance/recent-events/coronavirus-faqs#buy-travel-insurance"
              target="_blank"
              ><strong
                ><span style="text-decoration: none; color: windowtext"
                  >I want to buy a travel insurance policy today to cover
                  COVID-19 coronavirus. Am I covered?</span
                ></strong
              ></a
            ></span
          >
          <p>
            <strong><span>Overseas Medical</span></strong>
          </p>
          <p>
            <span
              >The only cover available is for Overseas Medical under Section 2.
              This benefit commences on you are on your trip.
            </span>
          </p>
          <p>
            <span>If you contract COVID-19 coronavirus while overseas</span
            ><span
              >, and as a result are hospitalised or are held in quarantine due
              to testing, you may be covered for overseas medical expenses and
              associated expenses under Section 2 of the policy, provided you
              had not entered into in a ‘Do Not Travel’ region at the time. If
              you are then deemed unfit to travel there may be limited cover
              relating to rescheduling your trip.</span
            >
          </p>
          <p>
            <span
              >Please contact the emergency assistance team for assistance,
              available 24 hours a day, 7 days a week. Their contact details can
              be found in the PDS under Overseas Emergency Assistance.</span
            >
          </p>
          <p>
            <strong
              ><span
                >All other sections of the policy including Section 1
                Cancellation</span
              ></strong
            >
          </p>
          <p>
            <span
              >Any policy purchased after 7 am (AEST) 31 January 2020, does not
              include cover for the COVID-19 coronavirus under the terms and
              conditions of the policy as described in the PDS made available to
              you prior to policy purchase. COVID-19 coronavirus is a known
              circumstance that would give rise to a claim.</span
            >
          </p>
          <p>
            <strong><span>General</span></strong>
          </p>
        </li>
        <li>
          <strong
            ><span
              >Does my policy have a General Exclusion for an Epidemic or
              Pandemic?</span
            ></strong
          >
          <p>
            <span
              >The policies do not include a General Exclusion specifically for
              an epidemic, pandemic or infectious disease.
            </span>
          </p>
          <p>
            <span>Our policy excludes cover for any known circumstance</span
            ><span>
              that would give rise to a claim. COVID-19 coronavirus was declared
              a global health emergency by the WHO (World Health Organisation)
              on the 31 January 2020. Therefore, coverage will based on your
              date of purchase of your policy.</span
            >
          </p>
        </li>
        <li>
          <strong><span>Should I contact my travel provider/s? </span></strong>
          <p>
            <span
              >Yes, we encourage all affected customers to contact their travel
              provider/s to discuss any actual or proposed changes to travel
              plans. Your travel provider can provide you with more information
              about your options for adjusting your travel itinerary or
              obtaining refunds or credits.
            </span>
          </p>
        </li>
        <li>
          <strong
            ><span
              >Where can I find other updates and information?</span
            ></strong
          >
          <p>
            <span
              >Stay up to date on travel information for COVID-19 coronavirus
              from the Department of Foreign Affairs and Trade (DFAT) </span
            ><span
              ><a href="https://www.smartraveller.gov.au/" target="_blank"
                ><span>Smart Traveller</span></a
              ></span
            ><span> website and </span
            ><span
              ><a href="https://www.who.int/" target="_blank"
                ><span>World Health Organisation</span></a
              ></span
            ><span> (WHO).</span>
          </p>
        </li>
        <li>
          <strong
            ><span>Can I still make a claim for consideration?</span></strong
          >
          <p>
            <span
              >Yes, please refer to the How to Claim link at the bottom of the
              page.</span
            >
          </p>
        </li>
      </ol>

      <p><span>&nbsp;</span></p>
      <p style="margin-bottom: 9pt">
        <span
          >The information provided above is general in nature only, and does
          not take into account your objectives, financial situation or needs.
          You should consider the appropriateness of the advice having regards
          to your objectives, financial situation and needs. You should obtain
          the </span
        ><em
          ><span
            >Travel Insurance Combined Financial Services Guide and Product
            Disclosure Statement'</span
          ></em
        ><span> (</span><strong><span>PDS</span></strong
        ><span
          >) and consider it before making a decision about whether to acquire
          travel insurance.
        </span>
      </p>
      <p style="margin: 0in 0in 9pt">
        <em
          ><strong><span style="color: #454849"></span></strong
        ></em>
      </p>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
