<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'TravelInsurance'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/cruise-travel.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Cruise Travel Insurance</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <h3 class="icon">International Cruise Travel Insurance</h3>
            <p>
              Whether you’re cruising through the Caribbean or touring the
              rivers of Thailand, the last thing on your mind is the possibility
              of something going wrong. Taking out International cruise
              insurance can be a wise precaution against a variety of unforeseen
              or emergency situations that might arise. In the event of illness,
              injury, delay, cancellation or other circumstances, a World2Cover
              cruise insurance policy can help prepare you and your family with
              coverage.
            </p>
            <p>
              There are three World2Cover international policies that cover
              cruises. You can decide what type of coverage and the price that
              is best suited for you based on your required needs. Please refer
              to the PDS and our TMD's to understand what product might best
              meet your needs. If you have questions do not hesitate to call our
              team! Our customer service team is available on (02) 9225 7599,
              Monday to Friday 8am to 9pm (AEST) and Saturday/Sunday 9am to 6pm
              (AEST).
            </p>
            <p>
              Cover for international and domestic river cruising is included
              automatically in the policy and you do not need to select the
              Cruise option to be covered for river cruising.
            </p>
            <p>
              All other international or domestic ocean cruising will require
              you to select the Cruise option to be covered while you are on
              your cruise.
            </p>
            <p>
              Once you’ve made your decision, simply purchase your policy online
              and enjoy your cruise!
            </p>
            <p class="font-12 mt-2">Policy criteria and conditions apply</p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Which policy might be right for me?</h3>
            <p>
              All World2Cover overseas policies provide 24-hour emergency
              medical assistance and unlimited overseas medical coverage **. Our
              goal is to provide quality cost effective coverage. The
              conditions, benefits and policy limits vary between our three
              policies. Take the time to read the
              <a href="/pds">PDS</a> to ensure you have the cover that is right
              for you.
            </p>
            <p class="mt-2">
              <small
                >**$unlimited means that generally there is no cap on the
                maximum dollar amount which may be paid out of this benefit,
                subject to the specific terms and conditions, sub-limits and
                exclusion that apply to this benefit. This benefit covers
                reasonable overseas medical and hospital costs as a result of an
                injury (including arising from a terrorist act) or illness
                occurring which first shows itself during your period of
                insurance. Benefits may be paid up to 12 months from the time
                you received treatment for the injury or illness, but only for
                reasonable expenses incurred during that time. All medical
                treatments must be provided by your treating doctor or our
                consulting medical officer. You must notify us as soon as
                possible of your admittance to hospital.<br />
                <span class="font-12"
                  >Policy criteria and conditions apply.</span
                ></small
              >
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Why should I consider cruise travel insurance?</h3>
            <p>
              Travel insurance for cruises offers additional peace of mind while
              you travel. World2Cover has your back so you can relax and enjoy
              your holiday.
            </p>
            <p>
              <b
                >Travel Insurance for international and domestic river
                cruising</b
              >
            </p>
            <p>
              Cover for international and domestic cruising is included
              automatically in the policy and you do not need to select the
              Cruise option to be covered.
            </p>
          </div>

          <div class="page-content-info">
            <h3 class="icon">Travel Insurance for cruising around Australia</h3>
            <p>
              If you are going on an ocean cruise which is only in Australian
              waters or calling into ports in Australia, you may still require a
              policy which includes medical cover whilst you are on board the
              ship (check with your cruise provider) as the medical providers
              are not registered with Medicare. You will therefore need to
              select Australian Cruise as your country of destination, which
              means you will be provided the international policy options and
              benefits, including medical and evacuation benefit whilst at sea,
              but not if you go to a medical provider while in port in
              Australia.
            </p>
            <p>
              While we do offer a
              <a href="/domestic-cover">domestic policy</a>, this coverage is
              not suitable for cruises. The Domestic policy does not include
              medical coverage, as we are a General Insurer and we are not
              authorised to sell medical insurance in Australia.
            </p>
          </div>

          <!--  -->
          <div class="py-3">
            <p class="text-gray">
              <small
                >Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438
                291 AFSL 246 548) is the issuer of World2Cover Travel Insurance.
                The 'World2Cover Travel Insurance - Combined Financial Services
                Guide and Product Disclosure Statement' (PDS) which includes the
                policy wording, is available <a href="/pds">here</a>. Any
                financial advice set out above is general in nature only, and
                does not take into account your objectives, financial situation
                or needs. Because of this, you should, before acting on the
                advice, consider the appropriateness of the advice, having
                regards to your objectives, financial situation and needs. You
                should consider the PDS in deciding whether to acquire, or to
                continue to hold the policies.</small
              >
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
