import { Component, OnInit } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { PDSService } from '../../../services/pds.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss',
})
export class FaqsComponent implements OnInit {
  pdsLink: string = '';

  constructor(
    private pdsService: PDSService,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.titleService.setTitle('AANT Travel insurance | FAQ');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Find answers to common questions about Travel Insurance. Our FAQ section covers everything you need to know about travel coverage, claims, policy details, and more. Get the information you need to travel with confidence.',
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: `AANT travel insurance FAQ
                  AANT travel insurance questions
                  Travel insurance FAQs
                  Common travel insurance questions
                  AANT insurance help
                  Travel coverage FAQ
                  AANT insurance support
                  Travel insurance information`,
    });
  }

  toggleCollapse(event: Event, index: string): void {
    const button = event.target as HTMLElement;
    const element = document.getElementById(index);
    if (element) {
      const isExpanded = element.classList.contains('show');
      element.classList.toggle('show', !isExpanded);
      button.classList.toggle('collapsed', isExpanded);
      button.setAttribute('aria-expanded', (!isExpanded).toString());
    }
  }

  ngOnInit(): void {
    this.pdsService.getLatestPDS().subscribe((data) => {
      if (data && data.pds && data.pds.length > 0) {
        this.pdsLink = data.pds[0].previewUrl;
      }
    });
  }
}
