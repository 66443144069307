import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss',
})
export class TermsAndConditionsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('AANT Travel insurance | Terms & Conditions');
    this.metaService.updateTag({
      name: 'description',
      content: `Review the Terms & Conditions for Travel Insurance. Understand the legal details, policy terms, and conditions that apply to your coverage. Ensure you're fully informed before purchasing your travel insurance policy.`,
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: `AANT travel insurance terms and conditions
                  AANT insurance T&Cs
                  Travel insurance terms
                  AANT travel policy details
                  Travel insurance T&Cs
                  AANT travel insurance policy terms
                  AANT travel insurance agreement`,
    });
  }
}
