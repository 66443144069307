import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Country } from '../interfaces/country';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CountryService {
  private countriesUrl = `${environment.baseAPIUrl}quote/configuration/countries`;
  //private countriesUrl = 'api/countries';

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private globalService: GlobalService) {}

  /** GET countries from the server */
  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(this.countriesUrl).pipe(
      tap((_) => console.log('fetched countries')),
      catchError(this.globalService.handleError<Country[]>('getCountries', [])),
    );
  }

  groupCountriesByState(term: string): Observable<Country[]> {
    if (!term.trim()) {
      // if not search term, return empty country array.
      return this.http.get<Country[]>(this.countriesUrl).pipe(
        map((x) => {
          console.log(`fetch countries `);
          return this.groupCountries(x);
        }),
        catchError(
          this.globalService.handleError<Country[]>('searchCountries', []),
        ),
      );
    }
    return this.http.get<Country[]>(`${this.countriesUrl}/?name=${term}`).pipe(
      map((x) => {
        x.length
          ? console.log(`found countries matching "${term}"`)
          : console.log(`no countries matching "${term}"`);

        return this.groupCountries(x);
      }),
      catchError(
        this.globalService.handleError<Country[]>('searchCountries', []),
      ),
    );
  }

  //////// Save methods //////////

  /** POST: add a new country to the server */
  addCountry(country: Country): Observable<Country> {
    return this.http
      .post<Country>(this.countriesUrl, country, this.httpOptions)
      .pipe(
        tap((newCountry: Country) =>
          console.log(`added country w/ id=${newCountry.regionId}`),
        ),
        catchError(this.globalService.handleError<Country>('addCountry')),
      );
  }

  /** DELETE: delete the country from the server */
  deleteCountry(id: number): Observable<Country> {
    const url = `${this.countriesUrl}/${id}`;

    return this.http.delete<Country>(url, this.httpOptions).pipe(
      tap((_) => console.log(`deleted country id=${id}`)),
      catchError(this.globalService.handleError<Country>('deleteCountry')),
    );
  }

  /** PUT: update the country on the server */
  updateCountry(country: Country): Observable<any> {
    return this.http.put(this.countriesUrl, country, this.httpOptions).pipe(
      tap((_) => console.log(`updated country id=${country.regionId}`)),
      catchError(this.globalService.handleError<any>('updateCountry')),
    );
  }

  private groupCountries(x: Country[]) {
    const grouped = x.reduce(
      (
        acc: any,
        {
          regionId,
          displayRegionName,
          ratingRegionName,
          countryName,
          countryCode,
          allowTravel,
          hasCalamity,
          calamityDescription,
          sortOrder,
          helixScore,
          isDomestic,
          isPASAMT,
          riskCategory,
          isSanctioned,
        },
      ) => {
        // Check if the state already exists in the accumulator
        const stateGroup = acc.find(
          (group: any) => group.ratingRegionName === ratingRegionName,
        );
        if (stateGroup) {
          // LM - TODO if countryCode exists already don't push it
          // If the state exists, push the country name into the countries array
          stateGroup.countries.push({
            regionId,
            displayRegionName,
            ratingRegionName,
            countryName,
            countryCode,
            allowTravel,
            hasCalamity,
            calamityDescription,
            sortOrder,
            helixScore,
            isDomestic,
            isPASAMT,
            riskCategory,
            isSanctioned,
          });
        } else {
          // If the state does not exist, create a new group with the state and country
          acc.push({
            ratingRegionName,
            countries: [
              {
                regionId,
                displayRegionName,
                ratingRegionName,
                countryName,
                countryCode,
                allowTravel,
                hasCalamity,
                calamityDescription,
                sortOrder,
                helixScore,
                isDomestic,
                isPASAMT,
                riskCategory,
                isSanctioned,
              },
            ],
          });
        }
        return acc;
      },
      [],
    );

    return grouped;
  }
}
