import { Component, OnInit } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { PDSService } from '../../../services/pds.service';
import { CommonModule, DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pds',
  standalone: true,
  imports: [
    CommonModule,
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './pds.component.html',
  styleUrl: './pds.component.scss',
  providers: [DatePipe],
})
export class PdsComponent implements OnInit {
  allPDSList: any[] = [];
  sameDatesPDSGroup: any[] = [];
  differentDatesPDSGroup: any[] = [];

  constructor(
    private pdsService: PDSService,
    private datePipe: DatePipe,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Product Disclosure Statements');
  }

  ngOnInit() {
    this.getAllPDSs();
  }

  getAllPDSs() {
    this.pdsService.getAllPDS().subscribe((response) => {
      if (response) {
        this.allPDSList = response?.pds.sort();
      }
      this.groupPdsByDates();
    });
  }

  groupPdsByDates(): void {
    const dateMap = new Map();
    for (const pds of this.allPDSList) {
      const key = `${pds.validFrom}-${pds.validUntil}`;
      if (!dateMap.has(key)) {
        dateMap.set(key, []);
      }
      dateMap.get(key).push(pds);
    }

    dateMap.forEach((value: any[], key: string) => {
      if (value.length > 1) {
        const tempArray = [
          {
            validFrom: value[0].validFrom,
            validUntil: value[0].validUntil,
            pds: value,
          },
        ];
        this.sameDatesPDSGroup = this.sameDatesPDSGroup.concat(tempArray);
      } else {
        this.differentDatesPDSGroup = this.differentDatesPDSGroup.concat(value);
      }
    });
  }

  formatDate(dateStr: string): string {
    const [day, month, year] = dateStr.split('/');
    const dateObj = new Date(+year, +month - 1, +day);

    const formattedDate = this.datePipe.transform(dateObj, 'd MMMM yyyy');
    const dayWithOrdinal = this.appendOrdinal(+day);

    return `${dayWithOrdinal} ${formattedDate?.slice(
      formattedDate.indexOf(' ') + 1,
    )}`;
  }

  appendOrdinal(n: number): string {
    if (n > 3 && n < 21) return n + 'th';
    switch (n % 10) {
      case 1:
        return n + 'st';
      case 2:
        return n + 'nd';
      case 3:
        return n + 'rd';
      default:
        return n + 'th';
    }
  }

  viewPDSPDF(pds: any): void {
    window.open(pds.previewUrl, '_blank');
  }

  downloadPDS(pds: any) {
    this.pdsService.downloadPDSPDF(pds.url, pds.name);
  }
}
