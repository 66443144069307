import { Injectable } from '@angular/core';
import { isMoment, Moment, isDate } from 'moment';
import moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    readonly defaultTimezone = "Australia/Sydney";

    constructor() {
        //setting up the default timezone
        moment.tz.setDefault(this.defaultTimezone);
    }

    public now(): Moment {
        return moment();
    }

    public isMoment(_moment: any): boolean {
        return isMoment(_moment) ? _moment.isValid() : false;
    }

    public getMomentFromDDMMYYYY(dateDDMMYYYY: string): Moment {
        if (!dateDDMMYYYY || typeof (dateDDMMYYYY) !== 'string' || dateDDMMYYYY.length > 10)
            this.throwErrorInvalidFormat(dateDDMMYYYY);

        const _moment = moment(dateDDMMYYYY, "DD/MM/YYYY");

        this.throwErrorIfNotAValidMoment(_moment);

        return _moment;
    }

    public getMomentFromDate(date: Date | string): Moment {
        return this.getMoment(date);
    }

    public getMoment(date: string | Date | Moment | number): Moment {
        this.throwErrorIfNull(date);

        let _moment: any;

        if (typeof (date) === 'string') {
            if (date.length <= 10)
                _moment = this.getMomentFromDDMMYYYY(date);
            else
                _moment = moment(date);
        }
        else if (date instanceof Date) {
            if (isNaN(date.getDate()))
                this.throwErrorInvalidDate(date);

            _moment = moment(date);
        }
        else if (this.isMoment(date)) {
            _moment = date;
        }
        else if (typeof (date) === 'number') {
            try {
                _moment = moment(date);
            }
            catch (error) {
                this.throwErrorInvalidFormat(date);
            }
        }
        else {
            this.throwErrorInvalidFormat(date);
        }

        this.throwErrorIfNotAValidMoment(_moment);

        return _moment;
    }

    public getDDMMYYYYFromDate(date: Date): string {
        return this.toDDMMYYYY(this.getMomentFromDate(date));
    }

    public getDateAsOfAESTFromDate(date: string | Date | Moment): Date {
        this.throwErrorIfNull(date);

        const _moment = this.getMoment(date);
        const _aestMoment = _moment.startOf("days");
        const _aestDate = new Date(_aestMoment.year(), _aestMoment.month(), _aestMoment.date());
        return _aestDate;
    }

    public formatDefault(_moment: Moment) {
        this.throwErrorIfNotAValidMoment(_moment);

        return this.toISO(_moment);
    }

    public toISO(_moment: Moment): string {
        this.throwErrorIfNotAValidMoment(_moment);

        return _moment.format();
    }

    public toDDMMYYYY(_moment: Moment): string {
        this.throwErrorIfNotAValidMoment(_moment);

        return _moment.format("DD/MM/YYYY");
    }

    public toDate(_moment: Moment): Date {
        this.throwErrorIfNotAValidMoment(_moment);

        return _moment.toDate();
    }

    public toLocalISO(_moment: Moment) {
        this.throwErrorIfNotAValidMoment(_moment);

        return this.toISO(_moment.local());
    }

    public toLocalDDMMYYYY(_moment: Moment) {
        this.throwErrorIfNotAValidMoment(_moment);

        return this.toDDMMYYYY(_moment.local());
    }

    public toLocalDate(_moment: Moment) {
        this.throwErrorIfNotAValidMoment(_moment);

        return this.toDate(_moment.local());
    }

    public ageInYears(dob: string | Date | Moment, pricingDate: string | Date | Moment) {
        if (!dob || !pricingDate)
            this.throwErrorInvalidFormat(dob);

        const dobMoment = this.getMoment(this.toDDMMYYYY(this.getMoment(dob)));
        const pricingDateMoment = this.getMoment(this.toDDMMYYYY(this.getMoment(pricingDate)));

        const age = pricingDateMoment.diff(dobMoment, 'years');

        if (age < 0)
            return 0;

        return age;
    }

    //dont use this function; contact pinakin

    public diffInDays(datea: string | Date | Moment | number, dateb: string | Date | Moment | number): number {
        if (!datea) {
            this.throwErrorInvalidFormat(datea);
        }

        if (!dateb) {
            this.throwErrorInvalidFormat(dateb);
        }

        const momenta = this.getMoment(datea);
        const momentb = this.getMoment(dateb);

        return momenta.diff(momentb, 'days');
    }


    //dont use this function; contact pinakin

    public addDays(momenta: Moment | Date, days: number): Date {
        if (!momenta) {
            this.throwErrorInvalidFormat(momenta);
        }

        if (!days) {
            this.throwErrorNullArgument();
        }

        const newDate = this.getMoment(momenta).add(days, 'days');

        return this.getDateAsOfAESTFromDate(newDate);
    }

    convertTimestamps(timestamps: number[][]): string[][] {
        return timestamps.map(range => range.map(timestamp => {
            const date = new Date(timestamp);
            const day = ('0' + date.getDate()).slice(-2);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }));
    }
    public parseDateString(dateString: string): Date {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day); // Note: month is 0-based in JavaScript Date
    }

    public convertTimestampDays(timestamps: string[][]): string[] {
        return timestamps.flatMap(range => {
          const startDate = this.parseDateString(range[0]);
          const endDate = range[1] ? this.parseDateString(range[1]) : startDate;
          const dates = [];
      
          for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            const day = ('0' + date.getDate()).slice(-2);
            const month = ('0' + (date.getMonth() + 1)).slice(-2);
            const year = date.getFullYear();
            dates.push(`${day}/${month}/${year}`);
          }
      
          return dates;
        });
      }
      

    public getDistinctDays(timestamps: any[][]): number {
        const allDates = this.convertTimestampDays(timestamps);
        return new Set(allDates).size;
    }

    //#region Error Handling
    private throwErrorIfNull(value: any): void {
        if (!value) {
            this.throwErrorNullArgument();
        }
    }

    private throwErrorIfNotAValidMoment(_moment: Moment): void {
        if (!this.isMoment(_moment)) {
            this.throwErrorInvalidDate(_moment);
        }
    }

    private throwErrorInvalidDate(obj: any): void {
        this.throwError(this.Errors.InvalidDate, obj);
    }

    private throwErrorInvalidFormat(obj: any): void {
        this.throwError(this.Errors.InvalidFormat, obj);
    }

    private throwErrorNullArgument(): void {
        this.throwError(this.Errors.NullArgument, "");
    }

    private throwError(errorCode: string, obj: string | object) {
        if (!errorCode)
            errorCode = this.Errors.Unknown;

        if (!obj)
            obj = "EMPTY MESSAGE.";

        let message;
        let dataType;

        if (typeof (obj) === 'string') {
            dataType = 'string';
            message = obj;
        }
        else if (obj instanceof Date) {
            dataType = 'Date';
            message = obj.toString();
        }
        else if (isMoment(obj)) {
            dataType = 'Moment';
            message = obj.toString();
        }
        else {
            dataType = 'unknown';
            this.throwErrorInvalidFormat(JSON.stringify(obj));
        }

        throw new Error(`${obj} ERROR MESSAGE: [${dataType}]: ${message}`);
    }

    private readonly Errors = {
        InvalidDate: "Not a valid date.",
        InvalidFormat: "Not a valid date format.",
        NullArgument: "Null argument.",
        Unknown: "Unknown error."
    }
    //#endregion
}
