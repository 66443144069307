import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  private validateUrl = `${environment.baseAPIUrl}quote/promocode/validate`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(private http: HttpClient, private globalService: GlobalService) {}

  validatePromoCode(code: string) {
    console.log('validate promotion code', code);
    return this.http
      .get<any>(
        `${this.validateUrl}/${environment.autoClub}/${code}`,
        this.httpOptions,
      )
      .pipe(
        tap((_) => console.log('fetched initial submission')),
        catchError(
          this.globalService.handleError<any>('initial submission', []),
        ),
      );
  }
}
