import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { RouterLink } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-travel-alerts',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
    RouterLink,
  ],
  templateUrl: './travel-alerts.component.html',
  styleUrl: './travel-alerts.component.scss',
})
export class TravelAlertsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('AANT Travel insurance | Travel Alerts');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Stay informed with Travel Insurance from AANT. Get the latest travel alerts and advisories to ensure your journey is safe and secure. Find updates on travel disruptions, safety warnings, and more. Keep your travel plans on track with our essential travel alerts.',
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: `AANT travel alerts
          AANT travel insurance updates
          Travel insurance alerts
          Travel safety alerts
          AANT travel warnings
          Insurance and travel alerts
          Travel disruption alerts
          AANT travel news`,
    });
  }
}
