import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PDSService } from '../../services/pds.service';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment';
import { LocalCacheService } from '../../services/localCache.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'racv-app-header',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './racv-header.component.html',
  styleUrl: './racv-header.component.scss',
})
export class RacvHeaderComponent implements OnInit {
  pdsLink: string = '';
  agentName: string = '';
  isPASUser: boolean = false;
  PASUrl: string = '';

  constructor(
    private globalService: GlobalService,
    private localCacheService: LocalCacheService,
    private pdsService: PDSService,
  ) {
    this.PASUrl = environment.PASUrl;
  }
  ngOnInit(): void {
    var agentGuid =
      this.globalService.getQueryParamInfo('AgentGuid') ||
      this.localCacheService.getSessionStorage('agentGuid');
    this.agentName =
      this.globalService.getQueryParamInfo('AgentName') ||
      this.localCacheService.getSessionStorage('agentName');
    if (this.agentName && agentGuid) {
      this.isPASUser = true;
    }
    const currentLatestPDS =
      this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
  }

  clearSession() {
    sessionStorage.clear();
    location.href = this.PASUrl;
  }
}
