<div class="theme-static">
    <section class="header position-fixed w-100">
        <app-racv-common-header></app-racv-common-header>
    </section>

    <!-- End Header Part -->

    <!-- Start Page Header Title Part -->
    <section class="page-header-title">
        <div class="page-title-overlay"></div>
        <img src="assets/images/aant/page-header/faqs.jpg" alt="" class="w-100 page-title-image" />
        <div class="page-header-title-caption">
            <h1>Hong Kong Protests</h1>
        </div>
    </section>
    <div class="container page-content py-5 page-content-info">
        <!-- <h3>Hong Kong Protests</h3> -->
        <p>
            Hong Kong continues to be plagued by widespread protests and civil unrest that has become unpredictable and
            expected to continue. As per current media reports Hong Kong Airport is closed or disrupted due to
            intensified protests at Hong Kong International Airport which have resulted in significant disruption,
            including the cancellation of flights. Our government website Smart Traveller advises to – Check your flight
            status on the Hong Kong International Airport website or with airlines directly. For Australians requiring
            consular assistance, please contact the Consular Emergency Centre +61 2 6261 3305 or the Australian
            Consulate +852 2827 8881. Our level of advice has not changed - 'exercise a high degree of caution'.</p>
        <p>All claims relating to this
            conflict will be assessed based on your circumstances and in accordance with the Product Disclosure
            Statement (PDS) applicable to your policy level.</p>
        <p>If your policy was purchased before
            7am (AEST) on 7 August 2019 –</p>
        <ul>
            <li>
                <p>If you have commenced your trip
                    and your transport has been delayed/cancelled there may be coverage for reasonable additional
                    expenses for travel, accommodation or meals. All policy limits, conditions and exclusions will apply
                    to any claim lodged.</p>
            </li>
            <li>
                <p>If you have not commenced your
                    trip and your transport has been cancelled, delayed or rescheduled you may have coverage for
                    cancellation or the rearrangement of your trip (if this is less than the cancellation cost). You
                    will need to contact your travel agent or transport provider to obtain any applicable refund, credit
                    or confirmation of cancellation fees applicable. Your travel provider may offer free options to
                    rearrange your travel. You must take all reasonable steps to mitigate your out of pocket expenses.
                </p>
            </li>
        </ul>
        <p>If you purchased your policy after
            7am (AEST) on 7 August 2019 –</p>
        <p>Our policy does not provide cover
            for any event you were aware of before your policy cover commenced. If you purchased your policy after 7am 7
            August 2019 we would expect this was done with knowledge of the current event and therefore there would be
            no coverage for any claim which was a result of this event.</p>
        <p><strong><em>Please note this is a
                    developing situation with possible military action that could affect coverage under the policy. We
                    will provide updates as the situation unfolds</em></strong></p>
    </div>
    <app-racv-footer></app-racv-footer>
</div>