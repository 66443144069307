import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-step-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './step-bar.component.html',
  styleUrl: './step-bar.component.scss'
})
export class StepBarComponent implements OnInit {
  @Input() customStyles: any = {};
  @Input() activeStep: any;

  ngOnInit() {}
}
