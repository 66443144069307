<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/pds.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Product Disclosure Statements</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-8 pe-lg-4">
          <div class="page-content-info">
            <p>
              A Product Disclosure Statement (PDS) is an important document
              designed to help You make an informed choice when purchasing Your
              World2Cover Travel Insurance. Please take the time to read it
              before purchasing this insurance as the PDS contains information
              regarding:
            </p>

            <ul class="text-gray">
              <li>The policy terms</li>
              <li>What the policy does or does not cover</li>
              <li>The terms, conditions and limitations of the policy</li>
              <li>The key features and benefits</li>
              <li>Your rights and obligations and</li>
              <li>How the Premium is calculated</li>
            </ul>
          </div>

          <div
            class="card mb-3 rounded-10"
            *ngFor="let pds of differentDatesPDSGroup"
          >
            <div class="card-body" *ngIf="pds.isActive">
              <div class="row">
                <div class="col-lg-5 col-md-12 col-12">
                  <div class="text-gray">
                    <p>
                      For any Policy Purchase <strong>ON</strong> or
                      <strong>AFTER</strong> the
                      {{ formatDate(pds.validFrom) }}, see Combined PDS below:
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-7">
                  <div class="pds-image">
                    <div
                      *ngIf="!pds.thumbnailUrl"
                      class="image-skeleton w-100 rounded"
                    ></div>
                    <img
                      *ngIf="pds.thumbnailUrl"
                      [src]="pds.thumbnailUrl"
                      alt=""
                      class="w-100 rounded"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-5">
                  <div class="pds-button">
                    <button class="btn-pds rounded" (click)="viewPDSPDF(pds)">
                      <span class="d-block"
                        ><img src="assets/icons/aant/visibility.png" alt=""
                      /></span>
                      View PDS
                    </button>
                    <div class="space-20"></div>
                    <button class="btn-pds rounded" (click)="downloadPDS(pds)">
                      <span class="d-block"
                        ><img src="assets/icons/aant/download.png" alt=""
                      /></span>
                      Download PDS
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" *ngIf="!pds.isActive">
              <div class="row">
                <div class="col-lg-5 col-md-12 col-12">
                  <div class="text-gray">
                    <p>
                      For any Policy Purchase <strong>ON</strong> or
                      <strong>AFTER</strong> the
                      {{ formatDate(pds.validFrom) }} and
                      <strong>BEFORE</strong> the
                      {{ formatDate(pds.validUntil) }}, see Combined PDS below:
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-7">
                  <div class="pds-image">
                    <div
                      *ngIf="!pds.thumbnailUrl"
                      class="image-skeleton w-100 rounded"
                    ></div>
                    <img
                      *ngIf="pds.thumbnailUrl"
                      [src]="pds.thumbnailUrl"
                      alt=""
                      class="w-100 rounded"
                    />
                  </div>
                </div>
                <div class="col-lg-3 col-5">
                  <div class="pds-button">
                    <button class="btn-pds rounded" (click)="viewPDSPDF(pds)">
                      <span class="d-block"
                        ><img src="assets/icons/aant/visibility.png" alt=""
                      /></span>
                      View PDS
                    </button>
                    <div class="space-20"></div>
                    <button class="btn-pds rounded" (click)="downloadPDS(pds)">
                      <span class="d-block"
                        ><img src="assets/icons/aant/download.png" alt=""
                      /></span>
                      Download PDS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="card rounded-10 mb-4"
            *ngFor="let groupPDS of sameDatesPDSGroup"
          >
            <div class="card-body">
              <div class="text-gray">
                <p>
                  <strong>IF</strong> your Policy was purchased
                  <strong>AFTER</strong> the
                  {{ formatDate(groupPDS.validFrom) }} and
                  <strong>BEFORE</strong> the
                  {{ formatDate(groupPDS.validUntil) }} see the appropriate PDS
                  below:
                </p>
              </div>
              <div class="row">
                <div
                  class="col-lg-3 col-6 mb-3"
                  *ngFor="let pds of groupPDS.pds"
                >
                  <div class="related-pds">
                    <div class="related-pds-image mb-3">
                      <div
                        *ngIf="!pds.thumbnailUrl"
                        class="image-skeleton w-100 rounded"
                      ></div>
                      <img
                        *ngIf="pds.thumbnailUrl"
                        [src]="pds.thumbnailUrl"
                        alt=""
                        class="w-100 rounded border"
                      />
                    </div>
                    <div class="d-flex justify-content-between">
                      <button
                        class="btn btn-pds-related w-50 me-1"
                        (click)="viewPDSPDF(pds)"
                      >
                        <img src="assets/icons/aant/visibility.png" alt="" />
                      </button>
                      <button
                        class="btn btn-pds-related w-50"
                        (click)="downloadPDS(pds)"
                      >
                        <img src="assets/icons/aant/download.png" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
        <div class="col-lg-4">
          <app-static-search></app-static-search>
        </div>
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
