import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GlobalService } from './global.service';

@Injectable({ providedIn: 'root' })
export class NgxBraintreeService {
  private createPurchaseURL = `${environment.baseAPIUrl}quote/submission/bind`; // URL to web api

  constructor(
    private http: HttpClient,
    private globalService: GlobalService, // private globalService: GlobalService,
  ) {}

  getClientToken(clientTokenURL: string): Observable<any> {
    this.globalService.showLoading('getClientToken');
    return this.http.get(clientTokenURL, { responseType: 'text' }).pipe(
      map((response: any) => {
        this.globalService.hideLoading('getClientToken');
        return response;
      }),
      catchError((error) => {
        throw error;
      }),
    );
  }

  createPurchase(x_api_key: string, purchaseObject: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'x-api-key': x_api_key,
      'Content-Type': 'application/json; charset=utf-8',
    });
    this.globalService.showLoading('createPurchase');
    return this.http
      .post(this.createPurchaseURL, purchaseObject, { headers: httpHeaders })
      .pipe(
        map((response: any) => {
          this.globalService.hideLoading('createPurchase');
          return response;
        }),
        catchError((err: HttpErrorResponse): any => {
          this.globalService.hideLoading('createPurchase');
          return throwError(err.error);
        })
      );
  }


sendPolicy(policyObject: any): Observable<any> {
  var sendPolicyUrl = `${environment.baseAPIUrl}quote/document/coi`; 
  //this.globalService.showLoading('sendPolicy');
  return this.http
    .post(sendPolicyUrl, policyObject)
    .pipe(
      map((response: any) => {
        //this.globalService.hideLoading('sendPolicy');
        return response;
      }),
    );
}
}

