import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalCacheService } from '../../services/localCache.service';
import { MedicalService } from '../../services/medical.service';
import { GlobalService } from '../../services/global.service';
import { MedicalPremium } from '../../interfaces/medical';
import * as xml2js from 'xml2js';
import { environment } from '../../../environments/environment';

interface AngularComponentReference {
  component: any;
  zone: any;
  loadAngularFunction: (eventType: any, result: any, status: any) => void;
}

declare global {
  interface Window {
    angularComponentReference?: AngularComponentReference;
    AUTH_DATA: { uri: string };
  }
}

@Component({
  selector: 'app-verisk-widget',
  templateUrl: './verisk-widget.component.html',
  styleUrl: './verisk-widget.component.scss',
})
export class VeriskWidgetComponent implements OnInit, AfterViewInit {
  requestObj: any;
  link: any;
  script: any;
  isWidgetEnabled: boolean = false;
  // private scriptTag: HTMLScriptElement;

  constructor(
    public dialogRef: MatDialogRef<VeriskWidgetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private localCacheService: LocalCacheService,
    private medicalService: MedicalService,
    private globalService: GlobalService,
  ) {}

  closeDialog(): void {
    this.dialogRef.close({ isPopupClosed: true });
  }

  onFinishVerisk(eventType: any, result: any, status: string) {
    if (status == 'success') {
      this.onSuccessVerisk(eventType, result);
    } else {
      this.onErrorVerisk(eventType, result);
    }
  }
  onSuccessVerisk(eventType: any, result: any) {
    switch (eventType) {
      case 'COMPLETE_SCREENING':
        const resultObject = JSON.parse(decodeURIComponent(result));
        const detail = this.globalService.getCurrentAssessmentDetails();
        const medicalPremium: MedicalPremium = {
          transactionId: detail?.transactionId,
          travelerId: detail?.travelerId,
          xmlResult: resultObject[0].Result,
          isUpdated: true,
        };
        this.medicalService
          .addMedicalPremium(medicalPremium)
          .subscribe((response) => {
            console.log('save medical premium', response);
            this.localCacheService.saveSessionStorage(
              'medicalPremium',
              response,
            );
            detail.xmlResult = resultObject[0].Result;
            detail.medicalPremium = response;
            const assessmentDetails =
              this.localCacheService.getSessionStorage('assessmentDetails');
            const currentAssessment =
              this.localCacheService.getSessionStorage('currentAssessment');
            if (
              currentAssessment === 'primary' ||
              currentAssessment === 'secondary'
            ) {
              assessmentDetails[currentAssessment] = detail;
            } else if (currentAssessment === 'dependent') {
              assessmentDetails.dependent.map((dependent: any) => {
                if (dependent.travelerId === detail.travelerId) {
                  dependent.xmlResult = detail.xmlResult;
                  dependent.medicalPremium = detail.medicalPremium;
                }
              });
            }
            this.localCacheService.saveSessionStorage(
              'assessmentDetails',
              assessmentDetails,
            );

            this.dialogRef.close(result);
          });
        break;
      case 'SAVE_SCREENING':
        console.log(`Save screening: ${result}`);
        break;
      default:
        console.log(eventType + ' ' + result);
        break;
    }
    return result;
  }
  onErrorVerisk(eventType: any, errors: any) {
    switch (eventType) {
      case 'SAVE_SCREENING':
        for (var error of errors) {
          switch (error.code) {
            case 'SAVE_SCREENING_INVALID':
              console.log(`Save screening invalid: ${error.detail}`);
              this.dialogRef.close(error);
              break;
            case 'SAVE_SCREENING_UNEXPECTED':
              console.log(`Save screening unexpected: ${error.detail}`);
              break;
            default:
              console.log(`Save screening: ${error.detail}`);
              break;
          }
        }
        break;
      case 'RESUME_SCREENING':
        for (var error of errors) {
          switch (error.code) {
            case 'RESUME_SCREENING_INVALID':
              console.log(`Resume screening invalid: ${error.detail}`);
              break;
            case 'RESUME_SCREENING_UNEXPECTED':
              console.log(`Resume screening unexpected: 
  ${error.detail}`);
              break;
            case 'RESUME_SCREENING_EXPIRED':
              console.log(`Resume screening expired: ${error.detail}`);
              break;
            default:
              console.log(`Save screening: ${error.detail}`);
              break;
          }
        }
        break;
      default:
        console.log(`Default: Code: ${errors[0].code}; Message: 
  ${errors[0].detail};`);
        break;
    }
    return errors;
  }

  ngOnDestroy() {
    this.cleanupScript();
  }

  cleanupScript() {
    if (this.script) {
      this.renderer.removeChild(this.document.head, this.link);
      this.renderer.removeChild(this.document.head, this.script);
    }
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.medicalService.decryptXMLData().subscribe((result) => {
      if (result == undefined || result == '') {
        this.buildParameters(result);
      } else {
        xml2js.parseString(result.DecryptedXml ?? '', (err: any, res: any) => {
          if (err) {
            throw new Error('Error parsing XML');
          }
          this.buildParameters(
            res.Screening?.ScreeningPath[0]?.SystemData[0]?.ScreeningData[0],
          );
        });
      }
    });
  }

  buildParameters(previousScreeningData: any) {
    window['angularComponentReference'] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (eventType: any, result: any, status: any) =>
        this.onFinishVerisk(eventType, result, status),
    };

    (<any>(
      window
    )).AUTH_DATA.uri = `${environment.baseAPIUrl}quote/token/verisk/${environment.autoClub}`;
    // (<any>window).AUTH_DATA.uri = "http://localhost:5104/api/verisk/token/racv";
    // (<any>window).AUTH_DATA.uri = "https://racvuat.tmmatravel.com/Verisk/Authtoken?sitename=RACV";// + "?sitename=" + "RACV";

    // (<any>window).AUTH_DATA.config =
    // {
    //   headers: {
    //     authorization: 'Basic ' + "dGVzdHVzZXI6dDJhTUByY2gyQDIzIQ==",
    //     // custom: "TokioMarineUATAPI"
    //   }
    // };

    (<any>window).AUTH_DATA.data = { grant_type: 'password' }; //grant_type: "password" };

    (<any>window).USER_SETTINGS =
      this.medicalService.buidMedicalVeriskUserSettings(previousScreeningData);

    (<any>window).ROOT_URL = 'https://gateway.verisk.com/rating/au/travel/';

    this.link = this.renderer.createElement('link');
    this.link.rel = 'stylesheet';

    this.link.href =
      'https://blackbox30-travel.verisk.com.au/Content/BootstrapVerisk.css';

    //// Render in header
    this.renderer.appendChild(this.document.head, this.link);
    //// Render in body
    //this.renderer.appendChild(window.document.body, this.link);
    //// Render in element
    //this.renderer.appendChild(this.el.nativeElement, this.link);

    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src =
      'https://blackbox30-travel.verisk.com.au/Scripts/blackbox-3.1.0.js';
    //this.script.async = true;
    //this.script.defer = true;

    //// Render in header
    this.renderer.appendChild(this.document.head, this.script);
    //// Render in body
    //this.renderer.appendChild(window.document.body, this.script);
    //// Render in element
    //this.renderer.appendChild(this.el.nativeElement, this.script);
  }
}
