import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private envConfig = environment;

  constructor() {
    (window as any)['autoClub'] = this.envConfig?.autoClub;
  }

  getEnvConfig() {
    return this.envConfig;
  }
}
