<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/faqs.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Fiji's Cyclone Sarai</h1>
    </div>
  </section>
  <div class="container page-content py-5 page-content-info">
    <!-- <h3>Fiji's Cyclone Sarai</h3> -->
    <p>
      Travel insurance information for Fiji’s Cyclone Sarai The Fiji
      Meteorological Service issued a Tropical Cyclone Alert for Fiji on
      Thursday December 26th. They have advised that the Tropical Cyclone named
      Sarai may bring severe weather, including strong winds, flooding and storm
      surges.
    </p>
    <p>
      All claims relating to Fiji’s Cyclone Sarai will be assessed based on your
      circumstances and in accordance with the Product Disclosure Statement
      (PDS) applicable to your policy level. As always please consider your
      safety first and follow the advice from local authorities. Flights may be
      disrupted, delayed or cancelled due to the cyclone and related weather
      events, please contact your airline/travel provider for details on your
      flight/travel arrangements. In addition, please keep up to date with the
      situation though local authorities, the advice issued by DFAT via its
      website
      <a href="https://www.smartraveller.gov.au" target="_blank"
        >smartraveller.gov.au</a
      >, local news, media and other sources who may also help you stay current
      with the changing situation.
    </p>
    <p>
      If your policy was purchased before 11am (AEST) on 27 December 2019 – If
      you have commenced your trip and - your transport has been
      delayed/cancelled there may be coverage for reasonable additional expenses
      for travel, accommodation or meals. All policy limits, conditions and
      exclusions will apply to any claim lodged. - If you are affected and
      require medical assistance, please contact our Emergency Assistance team
      on: + 61 2 8055 1683 - For general enquiries + 61 2 9225 7599 If you have
      not commenced your trip and - your transport has been cancelled, delayed
      or rescheduled you may have coverage for cancellation or the rearrangement
      of your trip (if this is less than the cancellation cost). You will need
      to contact your travel agent or transport provider to obtain any
      applicable refund, credit or confirmation of cancellation fees applicable.
      Your travel provider may offer free options to rearrange your travel. If
      you purchased your policy after 11am (AEST) on 27 December 2019 – Our
      policy does not provide cover for any event you were aware of before your
      policy cover commenced. If you purchased your policy after 11am (AEST) 27
      December 2019 we would expect this was done with knowledge of the current
      weather event and therefore there would be no coverage for any claim which
      was a result of this event.
    </p>
    <p>We will continue to monitor the situation and advise accordingly.</p>
  </div>
  <app-racv-footer></app-racv-footer>
</div>
