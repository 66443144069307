<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/refunds.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Refunds notice</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <h3>Cancelling within the cooling-off period</h3>
            <p>
              You have 21 days from the day You purchase Your policy to decide
              if the cover is right for You and suits Your needs. If the policy
              does not meet Your needs You can cancel Your policy within this
              “cooling-off period” for a full refund, provided You:
            </p>
            <ul class="text-gray link">
              <li>Haven’t started Your Trip; and/or</li>
              <li>Haven’t made a claim; and/or</li>
              <li>
                Don’t intend to make a claim or exercise any other rights under
                Your policy.
              </li>
            </ul>
            <p>
              Simply contact Us on (08) 8925 5901 within the cooling-off period
              and We can arrange this for You.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Cancellation outside the cooling-off period</h3>
            <p>
              If You would like to cancel Your policy outside the cooling-off
              period then You can request Us to consider this, provided You:
            </p>
            <ul class="text-gray link">
              <li>Haven’t started Your Trip; and/or</li>
              <li>Haven’t made a claim; and/or</li>
              <li>
                Don’t intend to make a claim or exercise any other rights under
                Your policy.
              </li>
            </ul>
            <p>
              We will consider Your request and may at Our discretion provide
              You with a pro-rated refund. This refund (and Our decision in
              providing You with a refund which will not be unreasonably
              withheld) will be based on numerous factors including:
            </p>
            <ul class="text-gray link">
              <li>The level of cover/policy type chosen; and/or</li>
              <li>
                The date You purchased Your Policy and the date the Trip would
                have begun; and/or
              </li>
              <li>Any other extenuating circumstances.</li>
            </ul>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
