import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-how-to-claim',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './how-to-claim.component.html',
  styleUrl: './how-to-claim.component.scss',
})
export class HowToClaimComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('AANT Travel insurance | How to Claim');
    this.metaService.updateTag({
      name: 'description',
      content:
        'File a claim for Travel Insurance. Follow our simple step-by-step guide to ensure your claim is processed smoothly. Get the support you need and understand the documentation required to submit your travel insurance claim.',
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: `AANT travel insurance claim
                  Travel insurance claim steps
                  Filing a travel insurance claim
                  AANT insurance claim guide
                  Travel coverage claim process
                  AANT insurance claim form`,
    });
  }
}
