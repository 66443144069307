<section class="award">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-12">
        <div class="text-lg-start text-center mb-3 mb-sm-0">
          <h2 class="display-5 fw-bold">Our awards</h2>
          <p class="h4 fw-light mb-3">
            2023, 2022 & 2019 Multiple Travel Insurance Awards
          </p>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="awardflex pt-2 pt-sm-0">
          <a
            target="_blank"
            [routerLink]="['/information']"
            [queryParams]="{ pageName: 'world2coverawards' }"
            [queryParamsHandling]="'merge'"
          >
            <img class="" src="assets/images/aant/aw001.png" />
            <img class="" src="assets/images/aant/aw002.png" />
          </a>
          <a
            href="https://mozo.com.au/expertschoice/travel-insurance"
            target="_blank"
          >
            <img class="" src="assets/images/aant/aw003.png" />
            <img class="" src="assets/images/aant/aw004.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
