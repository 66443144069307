<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'HowToClaim'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/how-to-claim.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>How to Claim</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <h3>In an emergency</h3>
            <p>
              <span
                >When you are travelling, our emergency assistance teams can
                help you anywhere in the world by simply calling them. They are
                there for you, 24 hours a day, able to help with emergencies and
                advice.</span
              >
            </p>
            <p>
              <span
                >Our team, with over 50 years’ experience, are only a phone call away. Call them on&nbsp;<a
                  href="tel:+61 2 8055 1625"
                  >+61 2 8055 1625</a
                >&nbsp;(reverse charges from the overseas operator).</span
              >
            </p>
          </div>

          <div class="page-content-info">
            <h3>Call us</h3>
            <p>
              <span
                >Please contact our claims team on 1300 992 915 and we'll
                happily take your details to lodge a claim and guide you through
                the claim process.</span
              >
            </p>
          </div>

          <div class="page-content-info">
            <h3>Lodge a claim online</h3>
            <p>
              <span
                >The quickest way to lodge a claim with AANT is using our online claim lodgement which is available 24/7. Follow these easy steps to lodge your claim online: </span
              >
            </p>
            <ol class="text-gray">
              <li>
                <span style="font-family: arial"
                  >You will first need to register by utilising the email
                  address you used to purchase your policy</span
                >
              </li>
              <li>
                <span style="font-family: arial"
                  >Once registered you will receive an email to guide you to
                  setup your password</span
                >
              </li>
              <li>
                <span style="font-family: arial"
                  >If you have previously registered a claim just login using
                  your email and password</span
                >
              </li>
              <li>
                <span style="font-family: arial"
                  >Once you have logged in, complete your claim details by
                  answering all sections</span
                >
              </li>
              <li>
                <span style="font-family: arial"
                  >Once submitted, your claim will be assessed within 10 working
                  days and we will let you know if we require any further
                  information/documentation from you.</span
                >
              </li>
            </ol>

            <div>
              <a
                href="https://aant-onlineclaim.tmnfatravelinsurance.com.au/Account/Register"
                class="btn btn-outline-info btn-lg px-5 me-2"
                target="_blank">Register</a
              >
              <a
                href="https://aant-onlineclaim.tmnfatravelinsurance.com.au/Account/Login"
                class="btn btn-info btn-lg px-5"
                target="_blank">Login</a
              >
            </div>
          </div>

          <div class="page-content-info">
            <h3>Alternatively for general customer service you can</h3>
            <ul class="link">
              <li>
                Call us on:
                <a href="tel:+61 8 8925 5901">+61 8 8925 5901</a>
              </li>
              <li>
                Email us at:
                <a href="mailto: info@aant.com.au"
                  > info&#64;aant.com.au</a
                >
              </li>
            </ul>
          </div>

          <div class="page-content-info">
            <h3>Interpreting Services</h3>
            <p>
              We are pleased to offer assistance in the following ways if you
              need help understanding any aspect of your travel insurance policy
              or claim in your language.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Contact Translating and Interpreting Services</h3>
            <p>
              <span
                >Translating and Interpreting Services (TIS) are available 24/7
                and their interpreters speak over 160 languages. They're
                available over the phone and in person. Book an
                <a href="https://www.tisnational.gov.au/" target=" _blank"
                  >appointment with TIS</a
                >, or call them on 131 450. This is a free service.
              </span>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
