import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-refunds',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './refunds.component.html',
  styleUrl: './refunds.component.scss',
})
export class RefundsComponent {
  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle('AANT Travel insurance | Refunds');
    this.metaService.updateTag({
      name: 'description',
      content:
        'Learn about the Travel Insurance refund policy. Find out how to request a refund, understand the eligibility criteria, and the steps involved in cancelling your policy. Get detailed information on our refund process.',
    });
    this.metaService.updateTag({
      name: 'keywords',
      content: `AANT travel insurance refunds
                  Travel insurance refunds
                  AANT insurance cancellation
                  Travel insurance refund policy
                  Insurance premium refund
                  Travel coverage refunds`,
    });
  }
}
