<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/about-us.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>About us</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <p>
              World2Cover is an award-winning
              <a target="_blank" [routerLink]="['/travel-medical-insurance']"
                >online travel insurance</a
              >
              provider based on three core principles:
            </p>
            <ol class="text-gray">
              <li>delivering a great product that is cost effective</li>
              <li>working with transparency and flexibility</li>
              <li>providing exceptional customer service.</li>
            </ol>

            <h3>Our Mission:</h3>
            <p>
              Our objective is simple: to provide you with peace of mind when
              travelling.Whether you are travelling to the other side of the
              world or around Australia,we strive to give you the best
              experience you could ever imagine. From the moment you search for
              travel insurance up until you buy your policy or need to lodge a
              claim, we make the online process as seamless as possible.
            </p>
            <p>
              We are proud to offer our services 24 hours/7 days a week for
              online sales and via phone for overseas medical emergency
              assistance. We are also available 7 days a week over the phone for
              sales, service, and claims. Our experts at our call centre, claims
              department and overseas medical emergency assistance teams have
              your back.
            </p>
            <p>You reckon that’s something you’re interested in?</p>
          </div>

          <div class="page-content-info">
            <h3>Our History:</h3>
            <p>
              As a travel insurance company, we think it’s very important to
              remember and acknowledge our roots and where we originated.
              World2Cover is part of Tokio Marine & Nichido Fire Insurance Co.
              Ltd, which was founded in 1879 during the Meiji era in Japan. The
              idea is based on the Japanese spirit of Omotenashi: a spirit that
              endeavours to give back to the community.
            </p>
            <p>
              Based in Sydney and Melbourne, Tokio Marine & Nichido Fire
              Insurance Co. Ltd opened its doors in the land of Oz in 1963. Over
              150 employees work in our Australian branch, including in our
              sales and claims call centres.
            </p>
            <p>
              Flash forward to today, Tokio Marine & Nichido Fire Insurance Co.
              Ltd has an extensive reach around the world. With over 40,000
              employees in 45 countries, Tokio Marine & Nichido Fire Insurance
              Co. Ltd prides itself on being one of the top-ranking non-life
              insurance companies worldwide.
            </p>
            <p>
              Our goal of being a good company is based on three pillars:
              looking beyond profit, empowering people, and delivering on
              commitments. People over profit is core to our values. People are
              the backbone of our industry, and as such, they deserve to be
              treated with the utmost respect.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Our Product:</h3>
            <ol class="text-gray">
              <li>
                <a target="_blank" [routerLink]="['/domestic-cover']"
                  >Domestic</a
                >
                and International Cover
              </li>
              <li>
                Single Trip and
                <a target="_blank" [routerLink]="['/annual-multi-trip-cover']"
                  >Annual Multi trip</a
                >
              </li>
              <li>
                Three levels of international cover
                <ol>
                  <li>Top Cover</li>
                  <li>Essentials Package</li>
                  <li>Basics Cover</li>
                </ol>
              </li>
              <li>
                Automatically included in your travel insurance policy:
                <ol>
                  <li>21 days cooling off period to change your mind</li>
                  <li>
                    10% discount for second traveller (youngest one) on the base
                    premium before any medical loadings
                  </li>
                  <li>
                    Dependents who are not working full-time, aged 25 and under
                    and travelling with you for the entire trip are covered at
                    no additional cost, unless they have an existing medical
                    condition to declare (in which case there may be an
                    additional premium)
                  </li>
                  <li>
                    38
                    <a target="_blank" [routerLink]="['/existing-medical']">
                      existing medical conditions</a
                    >&nbsp;covered*
                  </li>
                  <li>
                    Most amateur sporting and adventure activities covered at no
                    extra cost<sup>&amp;</sup>
                  </li>
                </ol>
              </li>
              <li>
                Optional additions to customise your travel insurance policy:
                <ol>
                  <li>
                    Reduce Excess from our default of $200 to $100 or $0
                    (excluding basics cover)
                  </li>
                  <li>
                    <a target="_blank" [routerLink]="['/ski-cover']"
                      >Winter sports and Ski cover</a
                    >
                  </li>
                  <li>Cruise cover</li>
                  <li>Online existing medical conditions screening</li>
                </ol>
              </li>
              <li>
                Online claims process:
                <ol>
                  <li>
                    receive a response no more than 10 business days from
                    completed application
                  </li>
                  <li>Based in Australia to better understand you</li>
                </ol>
              </li>
            </ol>
          </div>

          <div class="page-content-info">
            <h3>Our Awards:</h3>
            <ol class="text-gray">
              <li>
                <a
                  target="_blank"
                  [routerLink]="['/information']"
                  [queryParams]="{ pageName: 'world2coverawards' }"
                  [queryParamsHandling]="'merge'"
                  >Canstar Outstanding Value Award</a
                >
                for
                <ol>
                  <li>
                    International Travel Insurance (2016, 2017, 2018, 2019,
                    2022)
                  </li>
                  <li>Seniors Travel Insurance (2017, 2018, 2019)</li>
                  <li>Domestic Travel Insurance (2016, 2017, 2018)</li>
                  <li>Cruise Travel Insurance (2017, 2018)</li>
                </ol>
              </li>
              <li>
                Mozo
                <ol>
                  <li>Travel Insurer of the Year (2018, 2019)</li>
                  <li>Exceptional Value Basic Travel Insurance (2018)</li>
                  <li>
                    Exceptional Value Comprehensive Travel Insurance (2018)
                  </li>
                  <li>Exceptional Value Annual Travel Insurance (2018)</li>
                  <li>Exceptional Value Cruise Travel Insurance (2018)</li>
                  <li>Exceptional Value Ski Travel Insurance (2018)</li>
                  <li>Exceptional Quality Travel Insurance (2018)</li>
                  <li>Exceptional Quality Annual Travel Insurance (2018)</li>
                </ol>
              </li>
            </ol>
            <p>
              <small
                >Dependents aged 25 &amp; under are included for no additional
                cost – provided they are financially dependent on their parents,
                not working full time, do not require a medical assessment and
                are travelling with you the whole time.<br />
                * For international cover only<br />
                ^Conditions and exclusions apply. Please read the Combined
                Product Disclosure Statement and Financial Services Guide
                (including Policy Wording) for more information under the
                heading General Exclusions.<br />
                &amp; Policy criteria and conditions apply
              </small>
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
