import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Component, OnInit } from '@angular/core';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { PDSService } from '../../../services/pds.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-travel-insurance',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
    CommonModule,
  ],
  templateUrl: './travel-insurance.component.html',
  styleUrl: './travel-insurance.component.scss',
})
export class TravelInsuranceComponent implements OnInit {
  constructor(
    private pdsService: PDSService,
    private sanitizer: DomSanitizer,
    private titleSerive: Title,
  ) {
    this.titleSerive.setTitle('Travel Insurance');
  }
  sanitizedHtml: SafeHtml | null = null;
  ngOnInit(): void {
    let href: string = '';
    switch (environment.autoClub.toUpperCase()) {
      case 'W2C':
        href = `<a href='/pds'>here</a>`;
        this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(href);
        break;
      case 'RACV':
      case 'RACQ':
      case 'RACT':
      case 'RAA':
      case 'RAC':
      case 'AANT':
        this.loadPdsLinkForOtherClubs();
        break;
    }
  }

  loadPdsLinkForOtherClubs() {
    this.pdsService.getLatestPDS().subscribe((data) => {
      const href = `<a rel="noopener noreferrer" target="_blank" href="${
        data && data.pds && data.pds.length > 0 ? data.pds[0].previewUrl : '#'
      }">here</a>`;
      this.sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(href);
    });
  }
}
