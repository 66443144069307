<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'WhatsCovered'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/what-cover.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Annual Multi-Trip Cover</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <p>
              World2Cover offers annual multi-trip travel insurance. Our Annual
              Multi-Trip can be a good alternative if You plan to make multiple
              Trips over a 12 month period. You can select a maximum duration
              limit of 30, 45 or 60 days per Trip at checkout for an unlimited
              amount of trips over a 12 month period (as long as the trip is
              more than 250km from your home, and no longer that the trip
              duration selected). Treat the avid adventurer in you. That is ace!
            </p>
          </div>
          <div class="page-content-info">
            <h3>What is covered with annual multi-trip travel insurance?</h3>
            <p>
              World2Cover’s annual multi-trip insurance policy can provide cover
              for items such as medical and hospital expenses when
              travelling<sup>&</sup>
            </p>
            <p>
              As a wanderer with a passion for travelling, you shouldn’t have to
              worry about coverage.
            </p>
            <p>
              When you purchase annual multi-trip insurance, you can take
              advantage of our new for old policy. New for old means that we
              will reimburse your damaged luggage for new luggage up to the same
              value amount <sup>#</sup>. The maximum amount of coverage for
              damaged luggage is $15,000. Sub-limits apply depending on the item
              category, which are outlined in Section 3 of the World2Cover
              annual multi-trip insurance PDS.
            </p>
            <p class="font-12">& Policy criteria and conditions apply</p>
          </div>

          <div class="page-content-info">
            <h3>
              Summary of covered items<sup><small>#</small></sup>
            </h3>
            <div class="card">
              <div class="card-body">
                <ul class="check-circle">
                  <li>$Unlimited** Overseas medical and hospital expenses</li>
                  <li>
                    $Unlimited** emergency medical &amp; evacuation expenses
                  </li>
                  <li>
                    $Unlimited^^ cancellation fees &amp; lost deposits<sup
                      >#</sup
                    >
                  </li>
                  <li>$Unlimited^^ emergency expenses#</li>
                  <li>Cash in Hospital# (up to $6,000)</li>
                  <li>Overseas Dental# (up to $2,000)</li>
                  <li>Domestic pets^<sup>#</sup></li>
                  <li>Domestic services^<sup>#</sup></li>
                  <li>24/7 global Medical emergency assistance</li>
                  <li>Travel agent fees</li>
                  <li>
                    Luggage<sup>#</sup> loss, theft, damage or delayed (new for
                    old, up to $15,000, sub-limits apply)
                  </li>
                  <li>Travel documents &amp; personal money</li>
                  <li>Rental vehicle excess<sup>#</sup></li>
                  <li>Accidental Death* &amp; Funeral expenses Overseas*</li>
                  <li>Legal liability^<sup>&amp;</sup> (up to $10,000,000)</li>
                  <li>
                    38 listed existing medical conditions covered<sup
                      >&amp;</sup
                    >
                  </li>
                  <li>
                    Most amateur sporting and adventure activities are covered
                    at no extra cost such as hot air ballooning&amp; and bungee
                    jumping<sup>&amp;</sup>
                  </li>
                </ul>

                <div class="bg-light p-3 rounded font-12">
                  Limits and sub-limits apply<br />
                  <span
                    >**$unlimited means that generally there is no cap on the
                    maximum dollar amount which may be paid out of this benefit,
                    subject to the specific terms and conditions, sub-limits and
                    exclusion apply to this benefit. This benefit covers
                    reasonable overseas medical and hospital costs as a result
                    of an injury (including arising from a terrorist act) or
                    illness occurring which first shows itself during your
                    period of insurance. Benefits may be paid up to 12 months
                    from the time you received treatment for the injury or
                    illness, but only for reasonable expenses incurred during
                    that time. All medical treatments must be provided by your
                    treating doctor or our consulting medical officer. You must
                    notify us as soon as possible of your admittance to
                    hospital.</span
                  >&nbsp;<br />
                  ^^$unlimited means that generally there is no cap on the
                  maximum dollar amount which may be paid out for this benefit,
                  subject to the specific terms and conditions, sub-limits and
                  exclusions that apply to this benefit.<br />
                  ^This cover is per policy<br />
                  *This cover is per person listed in Your Policy<br />
                  &amp; Policy criteria and conditions apply
                </div>
                <p class="mt-2">
                  Note: Trip maximums are set at 30, 45, and 60 days.
                </p>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Who does annual multi-trip travel insurance cover?</h3>
            <p>
              Our annual multi-trip travel insurance offers coverage for
              singles, couples, and families. Single coverage provides added
              peace of mind for one adult between the ages of 18 and 75 and
              family cover includes two adults who are travelling together.
              Dependents who are not working full-time, are aged 25 and under
              and travelling with you for the entire trip can be listed on the
              single and family travel insurance package. This is included at no
              additional cost, unless they have an existing medical condition to
              declare (in which case an additional premium may be required).
            </p>
            <p>
              We recognise that pets are near and dear to your heart. So, we’ve
              come up with a level of protection for your domestic pets that
              will make them feel like they are simply human. Your animal
              companion (cats or dogs) is covered up to $650 if they suffer an
              injury on your trip and require veterinary attention (provided
              that at the time of injury your pet was in the care of a relative,
              friend or boarding kennel or cattery)<sup>&</sup>
            </p>
            <p>
              If your trip is delayed due to an event covered under the annual
              multi-trip policy, you will be reimbursed $25 for boarding kennel
              or cattery fees for each 24-hour period past your original return
              date.
            </p>
            <p>
              With the annual multi-trip option, the sums insured under each
              section of your policy are automatically reinstated once each
              separate trip is completed in the 12 month period.
            </p>
            <p class="font-12">& Policy criteria and conditions apply</p>
          </div>

          <div class="page-content-info">
            <h3>How much does annual multi-trip travel insurance cost?</h3>
            <p>
              The cost of our annual multi-trip travel insurance can vary
              depending on how many days you’re travelling, where you’re going,
              and other factors. While the standard excess for annual multi-trip
              insurance is $200, you can reduce your excess to $100 or $0 by
              choosing to pay an additional premium when purchasing your policy.
              The excess, in the event of a claim, on World2Cover policies is
              charged on a per incident, rather than per traveller, basis. Check
              out our instant quote button for more information and a
              personalised cost!
            </p>
          </div>

          <div class="page-content-info">
            <h3>Cooling-off period and extending your cover</h3>
            <p>
              World2Cover gives you the opportunity to cancel your policy with a
              cooling-off period.You are able to cancel your policy at no cost
              to you 21 days from the date of issue, provided the trip has not
              started and you don’t want to make a claim or exercise any other
              right under the policy.
            </p>
            <p>
              If your departure or return is delayed due to a reason covered
              under the policy, your trip can be extended free of charge. For
              more information on your policy, please visit the
              <a href="/pds">Product Disclosure Statement</a> on our website.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Emergency Expenses</h3>
            <p>
              World2Cover understands that circumstances happen out of your
              control. Our annual multi-trip travel insurance covers
              accommodation, meals and travel expenses if your trip is disrupted
              due to serious injury, death in the family, natural disaster,
              civil unrest or riots, or any other reason you can claim under
              this section. Please visit the
              <a href="/pds">Product Disclosure Statement</a>&nbsp; section 4 on
              our website.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Why consider World2Cover?</h3>
            <p>
              World2Cover offers specially designed levels of cover to assist
              with your travel insurance needs and can help to provide you with
              peace of mind. We strive to provide superior customer service,
              travel insurance packages, and instant quotes. You have heaps of
              places to go, people to see, and adventures to experience. Take
              advantage of our packages because, as we know, you have the world
              to cover. Get an instant online quote today!
            </p>
          </div>

          <div class="page-content-info">
            <p class="font-12">
              <span
                >Tokio Marine &amp; Nichido Fire Insurance Co Ltd (ABN 80 000
                438 291 AFSL 246 548) is the issuer of World2Cover Travel
                Insurance. The 'World2Cover Travel Insurance - Combined
                Financial Services Guide and Product Disclosure Statement' (PDS)
                which includes the policy wording, is available
                <a href="/pds" target="_blank">here</a>. Any financial advice
                set out above is general in nature only, and does not take into
                account your objectives, financial situation or needs. Because
                of this, you should, before acting on the advice, consider the
                appropriateness of the advice, having regards to your
                objectives, financial situation and needs. You should consider
                the PDS in deciding whether to acquire, or to continue to hold
                the policies.</span
              >
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
