import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

export const httpHeaderInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const agentGuid = sessionStorage.getItem('agentGuid');
  if(!agentGuid){
    return next(req);
  }
  const modifiedReq = req.clone({
    setHeaders: {
      'X-Loginid': JSON.parse(agentGuid)
    }
  });
  return next(modifiedReq);
};
