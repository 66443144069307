import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { LocalCacheService } from '../../services/localCache.service';
import { FooterComponent } from '../../components/footer/footer.component';
import moment from 'moment';
import { Router } from '@angular/router';
import { Utility } from '../../utils/utils';
import { PDSService } from '../../services/pds.service';
import { RacvFooterComponent } from '../../components/racv-footer/racv-footer.component';
import { RacvHeaderComponent } from '../../components/racv-header/racv-header.component';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'payment-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    RacvHeaderComponent,
    RacvFooterComponent,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './payment-confirmation.component.html',
  styleUrl: './payment-confirmation.component.scss',
})
export class PaymentConfirmationComponent implements OnInit, AfterViewInit {
  isPaymentLoading: boolean = false;
  quoteDetail: any;
  quoteEnquiry: any;
  travelerDetails: any;
  insuredTravelerDetails: any;
  policyNumber: any;
  pdsLink: string = '';
  goAdminLink: string ='';
  agentGuid: string = '';
  constructor(
    private localCacheService: LocalCacheService,
    private router: Router,
    private pdsService: PDSService,
    private globalService: GlobalService,
  ) {}

  ngOnInit(): void {
    if (!this.globalService.checkAndValidatePage(5, false)) {
      this.router.navigate(['/error']);
    }
    this.quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    if (!this.quoteDetail) {
      this.router.navigate(['/']);
      return;
    }
    this.quoteEnquiry =
      this.localCacheService.getSessionStorage('quoteEnquiry');
    this.travelerDetails =
      this.localCacheService.getSessionStorage('travelerDetails');
    const paymentInfo = this.localCacheService.getSessionStorage('paymentInfo');
    if (paymentInfo) {
      const paymentInfoObj = JSON.parse(paymentInfo);
      this.policyNumber = paymentInfoObj.policyNumber;
    } else {
      this.policyNumber = null;
    }
    this.getInsuredTravelerDetails();
    const currentLatestPDS = this.localCacheService.getSessionStorage('lastestPDS');
    if (!currentLatestPDS) {
      this.pdsService.getLatestPDS().subscribe((data) => {
        if (data && data.pds && data.pds.length > 0) {
          this.pdsLink = data.pds[0].previewUrl;
        }
      });
    } else {
      this.pdsLink = currentLatestPDS?.previewUrl;
    }
    this.agentGuid = this.localCacheService.getSessionStorage('agentGuid');
    if (this.agentGuid) {
      const paymentInfoObj = JSON.parse(paymentInfo);
      this.goAdminLink = environment.PASUrl + "/policy-detail?policyguid=" + paymentInfoObj.policyGuid;
    }
  }

  ngAfterViewInit(): void {
    this.localCacheService.clearAllSession();
  }

  getInsuredTravelerDetails() {
    const { ages, travelers } = this.quoteDetail;

    const insuredTravelerDetails = travelers.map(
      (traveler: any, index: any) => ({
        name: `${traveler.firstName} ${traveler.lastName}`,
        age: ages[index] || '',
      }),
    );

    this.insuredTravelerDetails = insuredTravelerDetails;
  }

  get travelDays() {
    const depDate = moment(this.quoteEnquiry?.return);
    const rtnDate = moment(this.quoteEnquiry?.depart);
    const days = depDate.diff(rtnDate, 'days');
    return days;
  }

  get countryNames(): string {
    return this.quoteEnquiry?.travelDestination
      .map((country: any) => country.countryName)
      .join(', ');
  }

  get formattedInvoiceDate(): string {
    const parsedDate = moment(this.quoteDetail?.pricingDate, 'DD/MM/YYYY');
    return parsedDate.isValid()
      ? parsedDate.format('D MMMM YYYY')
      : 'Invalid date';
  }
}
