<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header
      [activeHeader]="'WhatsCovered'"
    ></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/what-cover.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Basics Cover</h1>
      <!-- <p>World2Cover makes family travel insurance easy! Compare our levels of cover and select the best family travel insurance for you.</p> -->
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <div class="row g-0">
        <div class="col-lg-10">
          <div class="page-content-info">
            <p>
              As the saying goes, let’s get back to basics. In many cases, basic
              means the most simple. In our case, however, the World2Cover
              basics travel insurance package could still provide you with basic
              cover you may need for your trip. Our basics cover gives you
              coverage in the areas you may need it most.
            </p>
          </div>
          <div class="page-content-info">
            <h3>What is covered with basics travel insurance?</h3>
            <p>
              World2Cover basics travel insurance provides you with added peace
              of mind on a wide range of items. From lost luggage to medical
              expenses, we have a level of cover that might be right for you.
            </p>
            <p>
              Our old for new policy feature is one of the many offered benefits
              on our basics cover policy. Old for new means that we keep the
              same level of value of your damaged items, up to the policy
              limits<sup>#</sup>. Please note that a pair or set of items is
              treated as one item (e.g. a pair of earrings, a camera body and
              its standard lens and accessories, or a set of golf clubs or a
              drone with its camera). If we decide to replace your luggage, we
              will replace it with brand new luggage.
            </p>
          </div>

          <div class="page-content-info">
            <h3>
              Summary of covered items<sup><small>#</small></sup>
            </h3>
            <div class="card">
              <div class="card-body">
                <ul class="check-circle">
                  <li>$Unlimited** Overseas Medical and Hospital</li>
                  <li>$Unlimited** emergency medical &amp; evacuation</li>
                  <li>Cash in Hospital<sup>#</sup> (up to $1,500)</li>
                  <li>Overseas Dental<sup>#</sup> (up to $500)</li>
                  <li>24/7 global medical emergency assistance</li>
                  <li>Emergency expenses<sup>#</sup> (up to $5,000)</li>
                  <li>
                    Luggage<sup>#</sup> loss, theft, damage or delayed&nbsp;
                  </li>
                  <li>Legal liability^<sup>&amp;</sup> (up to $10,000,000)</li>
                  <li>
                    38 listed existing medical conditions covered<sup
                      >&amp;</sup
                    >
                  </li>
                  <li>
                    Most amateur sporting and adventure activities are covered
                    at no extra cost, such as canyoning and hot air
                    ballooning<sup>&amp;</sup>
                  </li>
                </ul>

                <div class="bg-light p-3 rounded font-12">
                  #Limits and sub-limits apply <br />
                  **$unlimited means that generally there is no cap on the
                  maximum dollar amount which may be paid out of this benefit,
                  subject to the specific terms and conditions, sub-limits and
                  exclusion apply to this benefit. This benefit covers
                  reasonable overseas medical and hospital costs as a result of
                  an injury (including arising from a terrorist act) or illness
                  occurring which first shows itself during your period of
                  insurance. Benefits may be paid up to 12 months from the time
                  you received treatment for the injury or illness, but only for
                  reasonable expenses incurred during that time. All medical
                  treatments must be provided by your treating doctor or our
                  consulting medical officer. You must notify us as soon as
                  possible of your admittance to hospital. <br />
                  *This cover is per person listed in Your Policy <br />
                  ^This cover is per policy <br />
                  & Policy criteria and conditions apply
                </div>
              </div>
            </div>
          </div>

          <div class="page-content-info">
            <h3>Who does basics travel insurance cover?</h3>
            <p>
              Basics travel insurance offers coverage for singles, couples and
              families. Singles include one adult between the ages of 18 and 75
              and their declared dependents.
              <a href="/family-travel-insurance">Family cover</a> includes any
              two adults named on the policy travelling together and their
              declared dependents. Dependents who are not working full-time, are
              aged 25 and under and travelling with you for the entire trip can
              be listed on the single and family travel insurance package. This
              is included at no additional cost, unless they have an existing
              medical condition to declare (in which case an additional premium
              may be required).
            </p>
            <p>
              Cover is available to Australian residents under two conditions:
              your trip begins and ends in Australia and you purchase your
              policy ahead of your trip. Temporary residents& may also obtain
              cover under certain stipulations, please refer to our
              <a href="/pds">Product Disclosure Statement</a>.
            </p>
            <p class="font-12">& Policy criteria and conditions apply</p>
          </div>

          <div class="page-content-info">
            <h3>How much does basics travel insurance cost?</h3>
            <p>
              <a href="/travel-insurance">Travel insurance</a> costs depend on a
              variety of factors. We take into consideration where you are
              travelling, for how long, the age of adult travellers and more. If
              you file a claim with our basics travel insurance package, the
              standard excess is $200 AUD or you can reduce your excess to $100
              paying extra. Excess is charged on a per incident basis rather
              than per person. Get personalised pricing with our instant quote
              button.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Cooling-off period and extending your cover</h3>
            <p>
              You are able to cancel your policy at no cost to you 21 days from
              the date of issue, provided the trip has not started and you don’t
              want to make a claim or exercise any other right under the policy.
            </p>
            <p>
              World2Cover understands that circumstances happen out of your
              control. Your trip can be extended free of charge if your
              departure or return is delayed due to a reason covered under the
              policy. For more information on your policy, please visit the
              <a href="/pds">Product Disclosure Statement</a> section on our
              website.
            </p>
          </div>

          <div class="page-content-info">
            <h3>Emergency Expenses</h3>
            <p>
              All World2Cover travel insurance policies can provide cover for
              accommodation, meals and travel expenses& if your trip is
              disrupted due to serious injury, death in your or travelling
              companion’s family, natural disaster, civil unrest or riots, or
              other reason you can claim for under this section. please visit
              the <a href="/pds">Product Disclosure Statement</a> section 4 on
              our website.
            </p>
            <p class="font-12">&Terms, conditions and exclusions apply</p>
          </div>

          <div class="page-content-info">
            <h3>Why consider World2Cover?</h3>
            <p>
              World2Cover offers specially designed levels of cover to assist
              with your travel insurance needs and can help to provide you with
              peace of mind while travelling. We strive to provide superior
              customer service, 24/7overseas medical emergency assistance,
              travel insurance packages, and instant quotes. You have heaps of
              places to go, people to see, and adventures to experience. Take
              advantage of our packages because, as we know, you have the world
              to cover. Give us a call at 02 9225 7599 or get an instant online
              quote today!
            </p>
            <p class="font-12">&Terms, conditions and exclusions apply</p>
          </div>

          <div class="page-content-info">
            <p class="font-12">
              Tokio Marine & Nichido Fire Insurance Co Ltd (ABN 80 000 438 291
              AFSL 246 548) is the issuer of World2Cover Travel Insurance. The
              'World2Cover Travel Insurance - Combined Financial Services Guide
              and Product Disclosure Statement' (PDS) which includes the policy
              wording, is available
              <span *ngIf="sanitizedHtml" [innerHTML]="sanitizedHtml"></span>.
              Any financial advice set out above is general in nature only, and
              does not take into account your objectives, financial situation or
              needs. Because of this, you should, before acting on the advice,
              consider the appropriateness of the advice, having regards to your
              objectives, financial situation and needs. You should consider the
              PDS in deciding whether to acquire, or to continue to hold the
              policies.
            </p>
          </div>
        </div>

        <!-- Aside Part / Sidebar -->
      </div>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
