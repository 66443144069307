<div class="theme-static">
  <section class="header position-fixed w-100">
    <app-racv-common-header [activeHeader]="'Cruise'"></app-racv-common-header>
  </section>

  <!-- End Header Part -->

  <!-- Start Page Header Title Part -->
  <section class="page-header-title">
    <div class="page-title-overlay"></div>
    <img
      src="assets/images/aant/page-header/travel-alerts.jpg"
      alt=""
      class="w-100 page-title-image"
    />
    <div class="page-header-title-caption">
      <h1>Cruise</h1>
    </div>
  </section>
  <!-- End Page Ttile  -->

  <!-- Main Content -->
  <main class="page-content py-5">
    <div class="container">
      <p>
        We provide cover for river cruising and ocean cruising regardless of
        whether you are cruising overseas or within Australia subject to the
        terms and conditions and exclusions outlined in the
        <a target="_blank" href="{{ pdsLink }}">PDS</a>.
      </p>
      <p>
        Different level of cover are available so please review the sections
        below in conjunction with the
        <a target="_blank" href="{{ pdsLink }}">PDS</a> and
        <a target="_blank" href="{{ tmdLink }}">TMD</a> to assist you with
        selecting the right cover for your needs.
      </p>

      <ol>
        <h3>Going on an international cruise</h3>
        <li>
          <div>
            <u>
              <strong>International River Cruising</strong>
            </u>
          </div>
          <p>
            Cover for international river cruising is included automatically in
            your cover and there is no need to select the Cruise Option when
            travelling on a river cruise when overseas for example a river
            cruise on the Danube.
          </p>
          <p>
            Medical evacuation and medical costs will be covered with all river
            cruising subject to the policy terms and conditions.
          </p>
        </li>
        <li>
          <div>
            <u>
              <strong>Going on an Ocean Cruise</strong>
            </u>
          </div>
          <p>
            If you are going on an international ocean cruise, you will be
            covered for this travel by selecting the Cruise Option and adding
            all countries being visited to the destination list when quoting for
            a policy.
          </p>
          <p>
            Our definition of Cruise means an ocean voyage on a commercially
            operated vessel for more than one night. If you are travelling for
            only one night you will automatically be covered under the policy
            without selecting the Cruise Option.
          </p>
          <p>
            Medical evacuation and medical costs will be covered with all ocean
            cruising subject to the policy terms and conditions.
          </p>
          <p>
            There are currently no restrictions on the size of the vessel or on
            the medical facilities required onboard.
          </p>
        </li>
        <li>
          <div>
            <u>
              <strong>Open water sailing</strong>
            </u>
          </div>
          <p>
            Please note there is an exclusion on any Open Water Sailing which
            means sailing more than 12 nautical miles from any landmass. This
            applies to a vessel using the wind as its means of propulsion and
            for these type of vessels no cover under cruise cover can be
            provided unless the vessel stays within 12 nautical miles of any
            landmass. For example, a customer wishing to sail in a commercially
            operated sailing vessel along the coast of Croatia and always
            staying within 12 nautical miles of the landmass of Croatia can be
            covered via the Cruise Option. Conversely, a customer wishing to
            sail the Mediterranean in open water more than 12 nautical miles
            from any landmass at any time cannot be covered via the Cruise
            option and is specifically excluded under our activities.
          </p>
        </li>
        <br />
        <h3>Going on an Australian Cruise</h3>
        <li>
          <div>
            <u>
              <strong>Australian River Cruising</strong>
            </u>
          </div>
          <p>
            Cover for domestic river cruising is included automatically in your
            cover and there is no need to select the Cruise Option when
            travelling on a river cruise in Australia eg a river cruise on the
            Murray. Please select Australia as the destination of travel.
          </p>
          <p>
            However, it is important to understand that medical evacuation and
            medical costs are not covered for any domestic travel as medical
            costs are covered by Medicare while any medical evacuations costs
            will need to be covered by you.
          </p>
          <p>
            There are no restrictions on vessel size or whether medical
            facilities and medically trained staff are onboard.
          </p>
        </li>
        <li>
          <div>
            <u>
              <strong>Cruising from Australia to International ports</strong>
            </u>
          </div>
          <p>
            Many cruises depart from Australian ports to visit international
            ports of destination such as countries in the South Pacific or South
            East Asia.
          </p>
          <p>
            For these types of cruises, please select Australian Cruise and add
            all countries being visited in the destination list when quoting for
            a policy - this will ensure you have cover for any medical
            evacuation and medical costs while overseas or onboard but please
            note, any medical treatment received in Australia is not covered
            given this is excluded and provided by Medicare.
          </p>
        </li>
        <li>
          <div>
            <u>
              <strong
                >Cruising to Australian ports or offshore islands with medical
                facilities and trained medical staff onboard and commercially
                operated</strong
              >
            </u>
          </div>
          <p>
            Many cruises depart from Australian ports to visit local Australian
            ports only such as major city based ports, regional ports or inland
            river systems such as the Murray River.
          </p>
          <p>
            If your cruise has medical facilities and trained medical staff
            onboard and the cruise is commercially operated, please select
            Australian Cruise while quoting for a policy - this will ensure you
            have cover for any medical evacuation and medical costs while
            onboard but please note, any medical treatment received in Australia
            is not covered given this is excluded provided by Medicare.
          </p>
        </li>
        <li>
          <div>
            <u>
              <strong
                >Cruising to Australian ports or offshore islands with NO
                medical facilities and trained medical staff onboard or
                chartered for self-operation</strong
              >
            </u>
          </div>
          <p>
            Many cruises depart from Australian ports to visit local Australian
            ports only such as major city based ports, regional ports or inland
            river systems such as the Murray River.
          </p>
          <p>
            If these cruises have no medical facilities and staff or are
            chartered for self-operation, please select Australia – Domestic
            cover, while quoting for a policy.
          </p>
          <p>
            Please note that no cover is provided for medical treatment received
            onboard or for medical evacuation. Any medical treatment received in
            Australia is not covered given this is excluded and provided by
            Medicare.
          </p>
        </li>
        <li>
          <div>
            <u>
              <strong>Open Water Sailing</strong>
            </u>
          </div>
          <p>
            Please note there is an exclusion on any Open Water Sailing which
            means sailing more than 12 nautical miles from any landmass.
          </p>
          <p>
            This applies to a vessel using the wind as its means of propulsion
            and for these type of vessels no cover can be provided unless the
            vessel stays within 12 nautical miles of any landmass.
          </p>
        </li>
      </ol>
      <h3>Further questions?</h3>
      <p>Please call us on <a href="tel:08 8925 5901">08 8925 5901</a></p>
    </div>
  </main>
  <app-racv-footer></app-racv-footer>
</div>
