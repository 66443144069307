<section id="header" class="header w-100 sticky-top solidheader">
  <div id="div_theme" class="themeInfo theme-info-wrapper" *ngIf="isPASUser">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-sm-7">
          <div class="themeInfoText">
            Tokio Marine Travel Insurance Policy Administration System
          </div>
        </div>
        <div class="col-sm-5">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="text-md-end">
                <span class="number text-md-end agentName">
                  {{ agentName }}
                  <a id="PasUrl" (click)="clearSession()"
                    >← BACK TO PAS</a
                  ></span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid borderonscroll d-none d-lg-block"
    [ngClass]="{ 'has-pas-header': isPASUser }"
  >
    <div class="header-top pt-1 pb-1">
      <div class="container-xl">
        <div class="row justify-content-center justify-content-lg-end">
          <div class="col text-end pe-0">
            <span style="color: #b8222f" class="me-1"
              ><i class="fa-solid fa-phone"></i
            ></span>
            <span class="number text-primary me-2"
              ><a href="tel:(08) 8925 5901 " class="text-primary"
                >(08) 8925 5901
              </a></span
            >

            <img src="assets/icons/aant/calendar.png" class="calendericon" />
            <span class="dayntime">Mon-Fri 9am-5pm</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="container-fluid shadowonscroll"
    [ngClass]="{ 'has-pas-header-mobile': isPASUser }"
  >
    <div class="main-header">
      <div class="row">
        <nav
          class="navbar navbar-expand-lg p-0 bsb-navbar bsb-navbar-hover bsb-navbar-caret"
        >
          <div class="container-xl navwithbg p-2 pe-0">
            <a class="navbar-brand" href=""
              ><img class="mainlogo" src="assets/images/aant/logo.jpg"
            /></a>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon"
                ><img src="assets/images/aant/menu.png"
              /></span>
            </button>

            <div
              class="offcanvas offcanvas-start align-items-lg-end"
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header border-bottom">
                <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                  <a class="navbar-brand" href=""
                    ><img class="mainlogo" src="assets/images/aant/logo.jpg"
                  /></a>
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body">
                <ul class="navbar-nav">
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      routerLink="/travel-alerts"
                      target="_blank"
                      >Travel Alerts</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" routerLink="/faqs" target="_blank"
                      >FAQs</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" href="{{ pdsLink }}" target="_blanks"
                      >Product Disclosure Statement</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a
                      class="nav-link"
                      routerLink="/how-to-claim"
                      target="_blank"
                      >How to Claim</a
                    >
                  </li>
                  <li class="nav-item px-2">
                    <a class="nav-link" routerLink="/cruise" target="_blank"
                      >Cruise</a
                    >
                  </li>
                </ul>
              </div>
              <!-- Visible only in mobile menu -->
              <div
                class="offcanvas-footer offcanvas-header border-top flex-column text-center"
              >
                <div class="col-12">
                  <a href="tel:(08) 8925 5901"
                    ><i class="fa-solid fa-phone"></i>
                    <span class="fw-semibold ps-1"> (08) 8925 5901</span></a
                  >
                </div>
                <div class="col-12">
                  <i class="fa-solid fa-business-time"></i>
                  <span class="ps-1"> Mon-Fri - 9am-5pm </span>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>
