import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { GlobalService } from './global.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  private validateUrl = `${environment.baseAPIUrl}quote/membership/validate/`;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private globalService: GlobalService) {}

  validateMembershipNumber(memberNumber: string, familyName: string) {
    const autoClub = environment.autoClub.toLowerCase();
    let requestUrl = `${this.validateUrl}${autoClub}?memberNumber=${memberNumber}`;
    if (environment.autoClub === 'RACV') {
      requestUrl = `${requestUrl}&familyName=${familyName}`;
    }
    return this.http.post<any>(requestUrl, this.httpOptions).pipe(
      tap((_) => console.log('fetched validateMembershipNumber')),
      catchError(
        this.globalService.handleError<any>('validateMembershipNumber', []),
      ),
    );
  }
}
