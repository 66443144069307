import { Component } from '@angular/core';
import { RacvFooterComponent } from '../../../components/racv-footer/racv-footer.component';
import { StaticSearchComponent } from '../../../components/static-search/static-search.component';
import { RacvCommonHeaderComponent } from '../../../components/racv-common-header/racv-common-header.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-review-policy',
  standalone: true,
  imports: [
    RacvFooterComponent,
    StaticSearchComponent,
    RacvCommonHeaderComponent,
  ],
  templateUrl: './review-policy.component.html',
  styleUrl: './review-policy.component.scss',
})
export class ReviewPolicyComponent {
  constructor(private titleService: Title) {
    this.titleService.setTitle('Review of Policy Terms');
  }
}
